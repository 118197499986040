import { gql } from "@apollo/client";
import { useSubscription } from "@apollo/client/react";
import React, { useEffect, useState } from "react";
import { FullScoreBoard } from "../components/FullScoreBoard";
import { Logo } from "../components/Logo";
import { MatchStatusOverlay } from "../components/MatchStatusOverlay";
import { Scoreboard } from "../components/Scoreboard";
import { MatchDetailsInput, useMatchQuery } from "../generated/graphql";
import { Route, Link, RouteComponentProps, withRouter } from "react-router-dom";
import { getCameras } from "../services/camera-service";

const SubscribeMatchDetails = gql`
  subscription SubscribeMatchDetails {
    subscribeMatchDetails {
      id
      url
      showLogo
      batsmanOne
      batsmanTwo
      batsmanOneScore
      batsmanTwoScore
      bowler
      showScoresOverlay
      showFullScoreboard
      status
      showStatus
      switchTeams
      youtubeUrl
      overlay
      overlayManager
      matchDate
      matchName
      scrapingLink
      location
      noTimeLimit
      showFullScreenStatus
      matchStarted
    }
  }
`;

interface Camera {
  cameraName: string;
  cameraType: string;
  isActive: boolean;
  _id: string;
  matchId: string;
  streamId: string;
  streamUrl: string;
  location: string;
}

interface OverlayProps extends RouteComponentProps<any> {}
const cameraMap = new Map([
  ["/camera1", "Camera 1"],
  ["/camera2", "Camera 2"],
  ["/camera3", "Camera 3"],
  ["/camera4", "Camera 4"],
  ["/camera5", "Camera 5"],
]);

const OverlayTwo: React.FC<OverlayProps> = (props) => {
  const [cameras, setCameras] = useState<Camera[]>([]);
  const path = props.location.pathname;
  const cameraName = "Camera 2";
  const [matchId, setMatchId] = useState<string>();

  useEffect(() => {
    const cameras = async () => {
      try {
        const response = await getCameras();
        setCameras(response?.data);
      } catch (error) {}
    };
    cameras();
  }, [1]);

  useEffect(() => {
    const cameraFiltered = cameras.find(
      (camera) => camera.cameraName === cameraName
    );
    if (cameraFiltered?.matchId) {
      setMatchId(cameraFiltered.matchId);
    }
  }, [cameras]);

  const match = useMatchQuery({
    skip: matchId === null || matchId === undefined || matchId === "",
    variables: {
      id: matchId!,
    },
  });

  const [subscribeMatchDetails, setSubscribeMatchDetails] =
    useState<MatchDetailsInput>();

  const { data, loading } = useSubscription(SubscribeMatchDetails);

  useEffect(() => {
    if (match.data?.match) {
      console.log(match.data?.match);
      setSubscribeMatchDetails(match.data?.match);
    }
  }, [match]);

  useEffect(() => {
    document.body.style.background = "none";
    if (!loading && data?.subscribeMatchDetails) {
      if (!loading && data?.subscribeMatchDetails) {
        const camera = cameras.find(
          (camera) => camera.cameraName === cameraName
        );
        if (data?.subscribeMatchDetails?.id === camera.matchId) {
          setSubscribeMatchDetails(data?.subscribeMatchDetails);
        }
      }
    }
    console.log(loading);
    console.log(data);
    console.log(subscribeMatchDetails);
  }, [data]);

  return (
    <div>
      {subscribeMatchDetails?.showLogo ? <Logo /> : null}
      {subscribeMatchDetails?.showStatus ? (
        <MatchStatusOverlay
          matchId={matchId}
          status={subscribeMatchDetails?.status}
          showFullScreenStatus={subscribeMatchDetails?.showFullScreenStatus}
          matchName={subscribeMatchDetails?.matchName}
          matchStarted={subscribeMatchDetails?.matchStarted}
        />
      ) : null}
      {subscribeMatchDetails?.showFullScoreboard ? (
        <FullScoreBoard
          matchUrl={subscribeMatchDetails?.scrapingLink?.replace(
            "viewScorecard",
            "fullScorecard"
          )}
          matchId={matchId}
          noTimeLimit={subscribeMatchDetails?.noTimeLimit}
          switchTeams={subscribeMatchDetails?.switchTeams}
        />
      ) : null}
      {subscribeMatchDetails?.showScoresOverlay ? (
        <Scoreboard
          switchTeams={subscribeMatchDetails?.switchTeams}
          matchUrl={subscribeMatchDetails?.scrapingLink?.replace(
            "viewScorecard",
            "fullScorecard"
          )}
          matchId={matchId}
        />
      ) : null}
    </div>
  );
};

export default withRouter(OverlayTwo);
