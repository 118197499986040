import React, { useEffect, useState } from "react";
import { Row, Col, Switch } from "antd";
import styled from "styled-components";
import { useUpdateMatchDetailsMutation } from "../generated/graphql";

interface LogoSwitchProps {
  matchId: string;
  showLogo: boolean;
}

const SwtichControl = styled.div`
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: rgba(211, 211, 211, 0.5);
`;

export const LogoSwitch: React.FC<LogoSwitchProps> = ({
  matchId,
  showLogo,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [updateMatchDetails] = useUpdateMatchDetailsMutation();
  const handleEventChange = async () => {
    await updateMatchDetails({
      variables: { options: { id: matchId, showLogo: !isChecked } },
    });
    setIsChecked(!isChecked);
  };

  function onChange(checked) {
    setIsChecked(checked);
    handleEventChange();
  }

  useEffect(() => {
    setIsChecked(showLogo);
  }, [showLogo]);

  return (
    <SwtichControl>
      <Row>
        <Col span={20}>Logo</Col>
        <Col span={4}>
          <Switch checked={isChecked} onChange={onChange} />
        </Col>
      </Row>
    </SwtichControl>
  );
};
