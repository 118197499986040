import axios from "axios";
import { API_URL } from "../constants/index";

// const URL = "https://backend-dot-wowzalivestreaming.uc.r.appspot.com/";
const URL = API_URL;
export const createNewStream = (payload) => {
  return axios.post(URL + "api/youtubeapi/createBroadcast", payload);
};

export const stopBroadcast = (payload) => {
  return axios.post(URL + "api/youtubeapi/stopBroadcast", payload);
};
