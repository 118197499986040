import React from "react";
import { useEffect } from "react";
import cheerio from "cheerio";
import fetch from "node-fetch";
import { useState } from "react";
import { Wrapper } from "./Wrapper";
import styled from "styled-components";
import { BACKEND_URL } from "../constants";
import { useUpdateMatchDetailsMutation } from "../generated/graphql";
import hvantage from "../assets/hvantage.png";
import teacup from "../assets/teacup.png";

interface FullScoreBoardProps {
  matchUrl: string;
  matchId: string;
  noTimeLimit: boolean;
  switchTeams: boolean;
}

const MaterialContainer = styled.div`
  z-index: 10000000;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  display: flex;
  margin-left: 60%;
`;

const Container = styled.div`
  width: 80%;
  position: absolute;
  left: 20%;
  top: 20px;
  display: flex;
  background-color: transparent;
  height: max-content;
`;

const BowlingContainer = styled.div`
  width: 80%;
  position: absolute;
  left: 20%;
  display: flex;
  background-color: transparent;
  height: max-content;
`;

const TransparentBackground = styled.div`
  background-color: #dcdcdc;
  height: 25px;
  width: 90%;
  margin: auto;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const TeamContainer = styled.div`
  background-color: transparent;
  width: 80%;
  height: max-content;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
`;
const ImageContainer = styled.div`
  background-color: transparent;
  width: 15%;
  display: flex;
`;

const Team = styled.div`
  background-image: linear-gradient(#103371, #0053df, #05063a);
  height: 50px;
  display: flex;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;
const TeamNameStyle = styled.div`
  font-size: 32px;
  color: white;
  font-weight: 500;
  margin: auto;
  font-family: "Roboto", sans-serif;
`;
const PlayerDetails = styled.table`
  border-collapse: separate;
  border-spacing: 0 2px;
  width: 100%;
  table-layout: fixed;
`;
const TableRow = styled.tr`
  width: 100%;
`;
const PlayerBat = styled.td`
  text-transform: uppercase;
  color: Black;
  background-image: linear-gradient(#a7d3f6, #f0f8ff, #cae2f6);
  font-weight: 600;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  width: 25%;
  text-align: left;
  padding-left: 2rem !important;
  padding: 5px;
  border-right: 2px solid #ffffff;
`;
const PlayerField = styled.td`
  color: Black;
  background-image: linear-gradient(#cae2f6, #f0f8ff, #a7d3f6);
  width: auto;
  text-align: left;
  padding-left: 2rem !important;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  padding: 5px;
  font-weight: 500;
`;

const PlayerField1 = styled.td`
  color: Black;
  background-image: linear-gradient(#a7d3f6, #f0f8ff, #cae2f6);
  font-weight: 500;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  width: 25%;
  text-align: left;
  padding-left: 2rem !important;
  padding: 5px;
  border-right: 2px solid #ffffff;
`;

const NotOutPlayerBat = styled.td`
  text-transform: uppercase;
  color: white;
  background-image: linear-gradient(#4782e5, #6e97dd, #0e58d5);
  font-weight: 600;
  font-size: 16px;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  width: 25%;
  text-align: left;
  padding-left: 2rem !important;
  padding: 5px;
`;
const NotOutPlayerField = styled.td`
  color: white;
  background-image: linear-gradient(#4782e5, #6e97dd, #0e58d5);
  width: auto;
  text-align: left;
  padding-left: 2rem !important;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  padding: 5px;
  font-weight: 500;
  font-size: 16px;
`;

const ScoreField = styled.td`
  color: white;
  background-image: linear-gradient(#4782e5, #6e97dd, #0e58d5);
  font-weight: 600;
  width: 10%;
  text-align: left;
  padding-left: 2rem;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  font-weight: 500;
  font-size: 16px;
`;
const BallField = styled.td`
  color: white;
  background-image: linear-gradient(#4782e5, #6e97dd, #0e58d5);
  width: 10%;
  text-align: right;
  padding-right: 1rem;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  font-weight: 400;
  font-size: 12px;
`;

const NotOutScoreField = styled.td`
  color: white;
  background-image: linear-gradient(#2c3e4c, #747c87, #2c3e4c);
  font-weight: 500;
  font-size: 16px;
  width: 10%;
  text-align: left;
  padding-left: 2rem;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
`;
const NotOutBallField = styled.td`
  color: white;
  background-image: linear-gradient(#2c3e4c, #747c87, #2c3e4c);
  width: 10%;
  text-align: right;
  padding-right: 1rem;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  font-weight: 400;
  font-size: 12px;
`;

const Total = styled.td`
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  background-image: linear-gradient(#05063a, #0053df, #103371);
  text-align: left;
  padding-left: 2rem;
  height: 40px;
  border-bottom-left-radius: 5px;
`;

const Overs = styled.td`
  color: white;
  background-image: linear-gradient(#05063a, #0053df, #103371);
  width: 10%;
  text-align: left;
  padding-left: 1rem;
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
`;

const TotalOvers = styled.td`
  color: white;
  background-image: linear-gradient(#05063a, #0053df, #103371);
  width: 10%;
  text-align: left;
  padding-left: 1rem;
  font-size: 20px;
  font-weight: 700;
`;

const TotalScore = styled.td`
  color: white;
  background-image: linear-gradient(#041024, #515e74, #05063a);
  width: 20%;
  text-align: left;
  padding-left: 2rem;
  border-bottom-right-radius: 5px;
  font-size: 20px;
  font-weight: 700;
`;

const ImgPromo1 = styled.div`
  width: auto;
  float: left;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  margin: auto auto;
  padding-left: 5px;
`;

const NormalText = styled.span`
  color: black;
  padding-left: 2rem;
  font-weight: 500;
  font-size: 14px;
`;

const LinkText = styled.span`
  color: #024fd1;
  font-weight: 600;
`;

const Heading = styled.td`
  color: white;
  background-image: linear-gradient(#2c3e4c, #747c87, #2c3e4c);
  width: auto;
  text-align: left;
  padding-left: 2rem !important;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  padding: 5px;
  font-weight: 500;
  font-size: 16px;
`;

const BowlHead = styled.td`
  text-transform: uppercase;
  color: white;
  background-image: linear-gradient(#2c3e4c, #747c87, #2c3e4c);
  font-weight: 600;
  font-size: 16px;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  width: 30%;
  text-align: left;
  padding-left: 2rem !important;
  padding: 5px;
`;

export const FullScoreBoard: React.FC<FullScoreBoardProps> = ({
  matchUrl,
  matchId,
  noTimeLimit,
  switchTeams,
}) => {
  const [teamOneScore, setTeamOneScore] = useState<[]>([]);
  const [teamTwoScore, setTeamTwoScore] = useState<[]>([]);
  const [bowlingInfoTeamOne, setBowlingInfoTeamOne] = useState<[]>([]);
  const [bowlingInfoTeamTwo, setBowlingInfoTeamTwo] = useState<[]>([]);
  const [teamNames, setTeamNames] = useState<string[]>([]);
  const [changeBoard, setChangeBoard] = useState(false);
  const [updateMatchDetails] = useUpdateMatchDetailsMutation();
  useEffect(() => {
    const updateScoreDetails = async () => {
      // if (
      //   matchUrl &&
      //   (matchUrl.includes("sccacricket") || matchUrl.includes("cricclubs"))
      // ) {
        if (
          matchUrl
        ) {
        const data = await fetch(matchUrl);
        const $ = cheerio.load(await data.text());
        const matchTableEle = $(".match-table-innings");
        let completeTableData: any[] = [];
        matchTableEle.each((_, ele) => {
          const tableEle = $(ele).find("table");
          let tables: any[] = [];
          let arr: string[] = [];

          $(tableEle)
            .children()
            .each((_i, tableHeaderBody) => {
              const trEle = $(tableHeaderBody).children();
              trEle.each((_j, tr) => {
                arr = [];
                const trChildren = $(tr).children().length;
                $(tr)
                  .children()
                  .each((_, th) => {
                    if (
                      $(th).children().length > 1 &&
                      !$(th).hasClass("hidden-phone") &&
                      trChildren > 1
                    ) {
                      arr.push($($(th).find("a")[0]).text().trim());
                    } else {
                      arr.push($(th).text().trim());
                    }
                  });
                tables.push(arr);
              });
            });
          completeTableData.push(tables);
        });
        setTeamOneScore(completeTableData[0]);
        setTeamTwoScore(completeTableData[2]);
        setBowlingInfoTeamOne(completeTableData[1]);
        setBowlingInfoTeamTwo(completeTableData[3]);
      } else if (matchUrl && matchUrl.includes("chauka")) {
        const response = await fetch(
          BACKEND_URL + "chauka/" + encodeURIComponent(matchUrl)
        );
        const data = await response.json();
        const $ = cheerio.load(await data.content);
        const tablesEle = $(".table_align_right");
        let tables: any[] = [];
        let arr: string[] = [];
        let completeTableData: any[] = [];
        $(tablesEle).each((_, tableEle) => {
          $(tableEle)
            .children()
            .each((_, tableHeaderBody) => {
              let trEle = $(tableHeaderBody).children();
              trEle.each((_, tr) => {
                arr = [];
                $(tr)
                  .children()
                  .each((_, th) => {
                    arr.push($(th).text().trim());
                  });
                tables.push(arr);
              });
            });
          completeTableData.push(tables);
          tables = [];
        });

        setTeamOneScore(completeTableData[0]);
        setTeamTwoScore(completeTableData[2]);
        setBowlingInfoTeamOne(completeTableData[1]);
        setBowlingInfoTeamTwo(completeTableData[3]);
        const teamNamesEle = $(".box_head");
        let teamNames: string[] = [];
        $(teamNamesEle).each((_, teamName) => {
          teamNames.push($(teamName).text().trim());
        });
        setTeamNames(teamNames);
      }
    };
    updateScoreDetails();
  }, [matchUrl]);

  useEffect(() => {
    let switchingBoardInterval;
    const changeScoreBoard = () => {
      switchingBoardInterval = setTimeout(() => {
        setChangeBoard((changeBoard) => !changeBoard);
      }, 10000);
    };
    changeScoreBoard();
    return () => {
      clearTimeout(switchingBoardInterval);
    };
  }, []);

  useEffect(() => {
    let switchingBoardInterval;
    if (!noTimeLimit) {
      const changeScoreBoard = () => {
        switchingBoardInterval = setInterval(async function () {
          await updateMatchDetails({
            variables: {
              options: {
                id: matchId.toString(),
                showScoresOverlay: true,
                showFullScoreboard: false,
              },
            },
          });
        }, 20000);
      };
      changeScoreBoard();
    }
    return () => {
      if (switchingBoardInterval) {
        clearInterval(switchingBoardInterval);
      }
    };
  }, []);

  return (
    <div>
      {!switchTeams && teamOneScore?.length > 3 ? (
        <>
          {!changeBoard ? (
            <Wrapper>
              <b>{teamNames[0]}</b>
              <MaterialContainer>
                <InningsDetailsTable teamScores={teamOneScore} />
              </MaterialContainer>
            </Wrapper>
          ) : null}
          {changeBoard ? (
            <Wrapper>
              <b>{teamNames[1]}</b>
              <MaterialContainer>
                <BowlingDetailsTable
                  bowlingInfo={bowlingInfoTeamOne}
                  matchUrl={matchUrl}
                />
              </MaterialContainer>
            </Wrapper>
          ) : null}
        </>
      ) : null}
      {switchTeams && teamTwoScore?.length > 3 ? (
        <>
          {!changeBoard ? (
            <Wrapper>
              <b>{teamNames[2]}</b>
              <MaterialContainer>
                <InningsDetailsTable teamScores={teamTwoScore} />
              </MaterialContainer>
            </Wrapper>
          ) : null}
          {changeBoard ? (
            <Wrapper>
              <b>{teamNames[3]}</b>
              <MaterialContainer>
                <BowlingDetailsTable
                  bowlingInfo={bowlingInfoTeamTwo}
                  matchUrl={matchUrl}
                />
              </MaterialContainer>
            </Wrapper>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

const InningsDetailsTable: React.FC<{ teamScores: [] }> = ({ teamScores }) => {
  const renderScoredBoardFields = (element: []) => {
    if (element.join(" ").includes("not out")) {
      return (
        <>
          {element.map((th, i) => (
            <>
              {i === 0 && <NotOutPlayerBat>{th}</NotOutPlayerBat>}
              {i === 1 && <NotOutPlayerField>{th}</NotOutPlayerField>}
              {i > 1 && i < 7 && <NotOutScoreField>{th}</NotOutScoreField>}
            </>
          ))}
        </>
      );
    }
    if (element.join(" ").includes("Total")) {
      return (
        <>
          {element.map((th: string, i) => (
            <>
              {i === 0 && <Total>{th.split("(")[0]}</Total>}
              {i === 1 && <Overs>{th}</Overs>}
              {i > 1 && i < 7 && <TotalOvers>{th}</TotalOvers>}
            </>
          ))}
        </>
      );
    } else {
      return (
        <>
          {element.map((th: string, i) => (
            <>
              {i === 0 && <PlayerBat>{th.split("(")[0]}</PlayerBat>}
              {i === 1 && <PlayerField1>{th}</PlayerField1>}
              {i > 1 && i < 7 && <ScoreField>{th}</ScoreField>}
            </>
          ))}
        </>
      );
    }
  };
  return (
    <div
      id="batInfo"
      style={{
        width: "80%",
        position: "absolute",
        left: "10%",
        top: "15px",
        display: "flex",
        backgroundColor: "transparent",
        height: "auto",
      }}
    >
      <TeamContainer>
        <Team>
          <TeamNameStyle>{teamScores.slice(0, 1)[0][1]}</TeamNameStyle>
        </Team>
        <PlayerDetails>
          <TableRow>
            <BowlHead>Batsmen</BowlHead>
            <BowlHead></BowlHead>

            <Heading>Runs</Heading>
            <Heading>Balls</Heading>
            <Heading>4s</Heading>
            <Heading>6s</Heading>
            <Heading>SR</Heading>
          </TableRow>
          {teamScores.slice(1, teamScores.length - 1).map((element: []) => {
            return <TableRow>{renderScoredBoardFields(element)}</TableRow>;
          })}
        </PlayerDetails>
      </TeamContainer>
      <ImageContainer>
        <ImgPromo1>
          <img src={hvantage} />
          <img src={teacup} />
        </ImgPromo1>
      </ImageContainer>
    </div>
  );
};

const BowlingDetailsTable: React.FC<{ bowlingInfo: []; matchUrl: string }> = ({
  bowlingInfo,
  matchUrl,
}) => {
  const renderScoredBoardFields = (element: []) => {
    return (
      <>
        {element
          .slice(
            matchUrl && matchUrl.includes("chauka") ? 0 : 1,
            element.length
          )
          .map((th: string, i) => (
            <>
              {i === 0 && <PlayerBat>{th.split("(")[0]}</PlayerBat>}
              {i === 1 && <PlayerField1>{th}</PlayerField1>}
              {i > 1 && i < 7 && <ScoreField>{th}</ScoreField>}
            </>
          ))}
      </>
    );
  };
  const getTopValue = () => {
    const ele = document.getElementById("batInfo");
    if (ele) {
      const top = ele.clientHeight;
      return `${top + 30}px`;
    }
  };
  return (
    <div
      style={{
        width: "80%",
        position: "absolute",
        left: "10%",
        display: "flex",
        backgroundColor: "transparent",
        height: "max-content",
        top: "100px",
      }}
    >
      <TeamContainer>
        <Team>
          <TeamNameStyle>Bowling</TeamNameStyle>
        </Team>
        <PlayerDetails>
          <TableRow>
            <BowlHead>Bowler</BowlHead>
            <Heading>
              <b>Overs</b>
            </Heading>

            <Heading>Maidens</Heading>
            <Heading>Runs</Heading>
            <Heading>Wickets</Heading>
            <Heading>Economy</Heading>
            <Heading></Heading>
          </TableRow>
          {bowlingInfo.slice(1, bowlingInfo.length - 1).map((element: []) => {
            return <TableRow>{renderScoredBoardFields(element)}</TableRow>;
          })}
        </PlayerDetails>
      </TeamContainer>
      <ImageContainer>
        <ImgPromo1>
          <img src={hvantage} />
          <img src={teacup} />
        </ImgPromo1>
      </ImageContainer>
    </div>
  );
};
