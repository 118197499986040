import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  Modal,
  Select,
  Typography,
  Space,
  notification,
} from "antd";
import { getTodayMatches, updateMatch } from "../services/today-match-service";
import { getCameras, updateCameraInfo } from "../services/camera-service";
import moment from "moment";

const { Option } = Select;

const { Text } = Typography;

interface TodayMatchesProps {}

interface Camera {
  cameraName: string;
  cameraType: string;
  isActive: boolean;
  _id: string;
  matchId: string;
  streamId: string;
  streamUrl: string;
  location: string;
}

interface Match {
  batsmanOne?: string;
  batsmanOneScore?: number;
  batsmanTwo?: string;
  batsmanTwoScore?: number;
  bowler?: string;
  cameraId?: string;
  createdAt: string;
  _id: string;
  matchDate?: string;
  matchName?: string;
  name?: string;
  overlay?: string;
  overlayManager?: string;
  scrapingLink?: string;
  showFullScoreboard?: boolean;
  showLogo?: boolean;
  showScoresOverlay?: boolean;
  showStatus?: boolean;
  status?: string;
  switchTeams?: boolean;
  updatedAt: string;
  url?: string;
  youtubeUrl?: string;
  location?: string;
  matchTime?: string;
}

export const TodayMatches: React.FC<TodayMatchesProps> = ({}) => {
  const [todayMatches, setTodayMatches] = useState<Match[]>();
  const [cameras, setCameras] = useState<Camera[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedMatch, setSelectedMatch] = useState<Match>();

  useEffect(() => {
    const todayMatches = async () => {
      try {
        const response = await getTodayMatches({
          matchDate: moment(new Date()).format("YYYY-MM-DD"),
        });
        setTodayMatches(response?.data);
      } catch (error) {}
    };
    todayMatches();
  }, []);

  useEffect(() => {
    const cameras = async () => {
      try {
        const response = await getCameras();
        setCameras(response?.data);
      } catch (error) {}
    };
    cameras();
  }, []);

  const showModal = (match: Match) => {
    setSelectedMatch(match);
    setIsModalVisible(true);
  };
  const handleOk = () => {
    const match = selectedMatch;
    let camera = cameras.find((camera) => camera._id === selectedValue);
    if (camera?.matchId && camera?.matchId.trim() !== "") {
      notification["error"]({
        message: "Error in assigning camera",
        description:
          "A match is already assigned to " +
          camera.cameraName +
          " Please clear the match in overlay manager",
      });
    } else {
      match.cameraId = selectedValue;
      camera.matchId = match?._id;
      const updateMatches = async (match: Match) => {
        try {
          await updateMatch(match);
        } catch (error) {}
      };
      const updateCameraDetails = async (camera: Camera) => {
        try {
          const response = await updateCameraInfo(camera);
          setCameras(response?.data);
        } catch (error) {}
      };
      updateMatches(match);
      updateCameraDetails(camera);
      setIsModalVisible(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  function handleChange(value: string) {
    setSelectedValue(value);
  }

  return (
    <>
      <Row gutter={16}>
        {todayMatches &&
          todayMatches
            .filter(
              (match) =>
                match.cameraId === null || !match.hasOwnProperty("cameraId")
            )
            .map((match) => (
              <Col className="gutter-row" span={5}>
                <Card
                  title={match.matchName}
                  style={{ width: 300, marginTop: 16 }}
                  actions={[
                    <Button type="primary" onClick={(e) => showModal(match)}>
                      Assign
                    </Button>,
                  ]}
                >
                  <div>
                    <Text strong>Match Date: </Text>
                    {moment(Date.parse(match.matchDate)).format("DD-MM-YYYY")}
                  </div>
                  <div>
                    <Text strong>Match Time: </Text>
                    {match.matchTime}
                  </div>
                  <div>
                    <Text strong>Location: </Text>
                    {match.location}
                  </div>
                </Card>
              </Col>
            ))}
      </Row>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={280}
      >
        <Space direction="vertical">
          <Text strong>Select Camera</Text>
          <Select
            defaultValue={""}
            style={{ width: 220 }}
            onChange={handleChange}
          >
            {cameras &&
              cameras.map((camera) => (
                <Option key={camera._id} value={camera._id}>
                  {camera.cameraName} - {camera.location}
                </Option>
              ))}
          </Select>
        </Space>
      </Modal>
    </>
  );
};
