import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import './Register.css';
import LogoImage from '../../../assets/crickai.png';
import UploadImage from '../../../assets/upload_icon.png';
import { registerClub } from "../../../services/today-match-service";

const CompanyRegisterPage = () => {

    const history = useHistory();

    const [academyName, setAcademyName] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [area, setArea] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [pincode, setPincode] = useState("");
    const [size, setSize] = useState("");
    const [logo, setLogo] = useState("");


    const onRegisterBtnClick = async () => {

        let userData = JSON.parse(sessionStorage.getItem('userData'));

        let data = {
            userId: userData.userId,
            clubName: academyName,
            clubSize: size,
            clubLogo: logo,
            address: {
                street1: address1,
                street2: address2,
                area: area,
                city: city,
                state: state,
                country: country,
                pincode: pincode
            }
        }
        const response = await registerClub(data);

        let successResponse = response['data'];

        if (successResponse.status === 200) {
            history.push('/app');
        }

    }

    return (
        <div className="h-100">
            <img src={LogoImage} className="img-fluid logo-img" height="110px" width="110px" />
            <div className="h-100 d-flex justify-content-center align-items-center">
                <div className="p-5 w-35 card border-0 register-card">
                    <form>
                        <h5 className="text-center">Company Registration</h5>
                        <div className="mt-4">
                            <label className="form-label">Academy Name</label>
                            <input
                                type="text"
                                className="form-control shadow-none"
                                name="academyName"
                                value={academyName}
                                onChange={e => setAcademyName(e.target.value)} />
                        </div>
                        <div className="mt-4">
                            <label className="form-label">Address 1</label>
                            <input
                                type="text"
                                name="address1"
                                value={address1}
                                onChange={e => setAddress1(e.target.value)}
                                className="form-control shadow-none"
                            />
                        </div>
                        <div className="mt-4">
                            <label className="form-label">Address 2</label>
                            <input
                                type="text"
                                name="address2"
                                value={address2}
                                onChange={e => setAddress2(e.target.value)}
                                className="form-control shadow-none" />
                        </div>
                        <div className="mt-4">
                            <label className="form-label">Area</label>
                            <input
                                type="text"
                                name="area"
                                value={area}
                                onChange={e => setArea(e.target.value)}
                                className="form-control shadow-none" />
                        </div>
                        <div className="mt-4">
                            <label className="form-label">City</label>
                            <input
                                type="text"
                                name="city"
                                value={city}
                                onChange={e => setCity(e.target.value)}
                                className="form-control shadow-none" />
                        </div>
                        <div className="mt-4 d-flex">
                            <div className="p-1">
                                <label className="form-label">ZIP code</label>
                                <input
                                    type="number"
                                    name="pincode"
                                    value={pincode}
                                    onChange={e => setPincode(e.target.value)}
                                    className="form-control shadow-none" />
                            </div>
                            <div className="p-1">
                                <label className="form-label">State</label>
                                <select
                                    className="form-control shadow-none"
                                    name="state"
                                    value={state}
                                    onChange={e => setState(e.target.value)}
                                >
                                    <option value="Tamil Nadu">Tamil Nadu</option>
                                    <option value="Kerala">Kerala</option>
                                    <option value="Karnataka">Karnataka</option>
                                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                                    <option value="Others">Others</option>
                                </select>
                            </div>
                        </div>
                        <div className="mt-4">
                            <label className="form-label">Country</label>
                            <input
                                type="text"
                                name="country"
                                value={country}
                                onChange={e => setCountry(e.target.value)}
                                className="form-control shadow-none" />
                        </div>
                        <div className="mt-4">
                            <label className="form-label">Academy Size</label>
                            <input
                                type="number"
                                name="size"
                                value={size}
                                onChange={e => setSize(e.target.value)}
                                className="form-control shadow-none" />
                        </div>
                        <div className="mt-4 text-center d-flex flex-column justify-content-center align-items-center register__company__upload-photo">
                            <img src={UploadImage} className="img-fluid" height="110px" width="110px" />
                            <label className="form-label">Upload Photo</label>
                        </div>
                        <div className="mt-4">
                            <button type="button" className="w-100 btn btn-primary" onClick={onRegisterBtnClick}>Register</button>
                        </div>
                        <div className="mt-3 w-100 d-flex flex-column justify-content-end align-items-end">
                            {/* <Link to="/login"> */}
                            <span className="register__login-text text-decoration-none">Skip</span>
                            {/* </Link> */}
                        </div>
                    </form>
                </div>
            </div>
        </div>

    )

}


export default CompanyRegisterPage;

