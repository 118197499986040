import React, { useEffect, useState } from "react";
import { Row, Col, Switch } from "antd";
import styled from "styled-components";
import { useUpdateMatchDetailsMutation } from "../generated/graphql";

interface ScoreboardSwitchProps {
  matchId: string;
  showScoresOverlay: boolean;
}

const SwtichControl = styled.div`
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: rgba(211, 211, 211, 0.5);
`;

export const ScoreboardSwitch: React.FC<ScoreboardSwitchProps> = ({
  matchId,
  showScoresOverlay,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [updateMatchDetails] = useUpdateMatchDetailsMutation();
  const handleEventChange = async () => {
    await updateMatchDetails({
      variables: {
        options: {
          id: matchId,
          showScoresOverlay: !isChecked,
        },
      },
    });
    setIsChecked(!isChecked);
  };

  function onChange(checked) {
    setIsChecked(checked);
    handleEventChange();
  }

  useEffect(() => {
    setIsChecked(showScoresOverlay);
  }, [showScoresOverlay]);

  return (
    <SwtichControl>
      <Row>
        <Col span={20}>Scores Overlay</Col>
        <Col span={4}>
          <Switch checked={isChecked} onChange={onChange} />
        </Col>
      </Row>
    </SwtichControl>
  );
};
