import axios from "axios";
import { API_URL } from "../constants/index";

// const URL = "https://backend-dot-wowzalivestreaming.uc.r.appspot.com/";
const URL = API_URL;

export const register = (payload) => {
    return axios.post(URL + "user/register", payload);
};

export const login = (payload) => {
    return axios.post(URL + "user/login", payload);
};