import axios from "axios";
import { API_URL } from "../constants/index";


// const URL = "https://backend-dot-wowzalivestreaming.uc.r.appspot.com/";
// const URL = "http://localhost:5000/";
const URL = API_URL;

export const getCameras = () => {
  return axios.get(URL + "api/cameras/");
};

export const updateCameraInfo = (payload) => {
  return axios.post(URL + "api/cameras/updatecameradetails", payload);
};
