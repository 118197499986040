import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getTodayMatches } from "../../../services/today-match-service";
import './MatchSummary.css';

const MatchSummaryPage = () => {

    const [matchSummaryData, setMatchSummaryData] = useState([]);

    useEffect(() => {
        getTodayMatchesSummary();
    }, [])

    const getTodayMatchesSummary = async () => {

        let data = {}

        const response = await getTodayMatches(data);

        setMatchSummaryData(response['data']);

        console.log('response ', response);
    }


    return (
        <div className="p-0 m-0 container-fluid match__summary-parent">
            <div className="h-100">
                {
                    matchSummaryData?.length !== 0 &&
                    <div className="w-100 card border-0 match__summary-card">
                        <div className="d-flex justify-content-between">
                            <div className="d-flex justify-content-center align-items-center">
                                <label className="p-1">Show</label>
                                <select className="p-1 form-control match__summary-pagination">
                                    <option>5</option>
                                    <option>10</option>
                                    <option>15</option>
                                    <option>20</option>
                                </select>
                                <span className="p-1">entries</span>
                            </div>
                            <div className="d-flex">
                                <label className="p-1">Search</label>
                                <input type="text" className="w-100 form-control" />
                            </div>
                        </div>
                        <div className="my-4">
                            <div>


                                <table className="table table-responsive">
                                    <thead>
                                        <tr>
                                            {/* <th scope="col">Match Type</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Team One</th>
                                    <th scope="col">Team Two</th>
                                    <th scope="col">Result</th>
                                    <th scope="col">Scores</th>
                                    <th scope="col">Actions</th> */}
                                            <th scope="col">Match Type</th>
                                            <th scope="col">Match ID</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Time</th>
                                            <th scope="col">Location</th>
                                            {/* <th scope="col">View</th> */}
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            matchSummaryData && matchSummaryData?.map((match, index) => {
                                                return (
                                                    <tr className="py-1" key={index}>
                                                        <th scope="row">Semi-finals</th>
                                                        <td>{match.ccMatchId}</td>
                                                        <td>{match.matchDate}</td>
                                                        <td>{match.matchTime}</td>
                                                        <td>{match.location}</td>
                                                        <td>
                                                            <select className="form-control shadow-none upcoming__match-actions-btn">
                                                                <option>Admin</option>
                                                                <option>Notify</option>
                                                            </select>
                                                        </td>
                                                    </tr>
                                                )
                                            }

                                            )
                                        }


                                        )

                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                }
            </div>
            {
                matchSummaryData?.length === 0 &&
                <div className="w-100 h-100 card border-0 d-flex justify-content-center align-items-center match__summary-card">
                    <h2>No Data found</h2>
                </div>
            }
        </div>

    )

}


export default MatchSummaryPage;

