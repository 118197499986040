import axios from "axios";
import { API_URL } from "../constants/index";

// const URL = "https://backend-dot-wowzalivestreaming.uc.r.appspot.com/";
const URL = API_URL;

export const getTodayMatches = (payload) => {
  return axios.post(URL + "api/match/gettodaymatch", payload);
};

export const updateMatch = (payload) => {
  return axios.post(URL + "api/match/updateoverlaymatch", payload);
};

export const getCameraMatch = (payload) => {
  return axios.post(URL + "api/match/getcameramatch", payload);
};

export const getOverlayMatchById = (matchId) => {
  return axios.get(URL + "api/match/getoverlaymatchbyid/" + matchId);
};

export const updateScoresByMatchId = (payload, matchId) => {
  return axios.post(URL + "api/match/updatematchscores/" + matchId, payload);
};

export const checkForMatchId = (payload) => {
  return axios.get(URL + "api/cc/checkForMatchId", payload);
};

export const registerClub = (payload) => {
  return axios.post(URL + "api/club/register", payload);
};

export const upcomingMatches = (payload) => {
  return axios.post(URL + "api/match/upcomingmatches", payload);
};


