import React, { useEffect, useState } from "react";
import { Row, Col, Switch, Checkbox } from "antd";
import styled from "styled-components";
import { useUpdateMatchDetailsMutation } from "../generated/graphql";

interface LogoSwitchProps {
  matchId: string;
  showStatus: boolean;
  showFullScreenStatus: boolean;
}

const SwtichControl = styled.div`
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: rgba(211, 211, 211, 0.5);
`;

export const ShowStatusSwitch: React.FC<LogoSwitchProps> = ({
  matchId,
  showStatus,
  showFullScreenStatus,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isFullScreenStatusChecked, setIsFullScreenStatusChecked] =
    useState(false);
  const [updateMatchDetails] = useUpdateMatchDetailsMutation();
  const handleEventChange = async () => {
    await updateMatchDetails({
      variables: { options: { id: matchId, showStatus: !isChecked } },
    });
    setIsChecked(!isChecked);
  };

  const handleFullScreenStatusEventChange = async () => {
    await updateMatchDetails({
      variables: {
        options: {
          id: matchId,
          showFullScreenStatus: !isFullScreenStatusChecked,
        },
      },
    });
    setIsFullScreenStatusChecked(!isFullScreenStatusChecked);
  };

  function onChange(checked) {
    setIsChecked(checked);
    handleEventChange();
  }

  function onFullScreenStatusChange(checked) {
    setIsFullScreenStatusChecked(checked);
    handleFullScreenStatusEventChange();
  }

  useEffect(() => {
    setIsChecked(showStatus);
    setIsFullScreenStatusChecked(showFullScreenStatus);
  }, [showStatus]);

  useEffect(() => {
    setIsFullScreenStatusChecked(showFullScreenStatus);
  }, [showFullScreenStatus]);

  return (
    <SwtichControl>
      <Row>
        <Col span={20}>Show status</Col>
        <Col span={20}>
          <Checkbox
            checked={isFullScreenStatusChecked}
            onChange={onFullScreenStatusChange}
          >
            <b>Full Screen Status</b>
          </Checkbox>
        </Col>
        <Col span={4}>
          <Switch checked={isChecked} onChange={onChange} />
        </Col>
      </Row>
    </SwtichControl>
  );
};
