import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { register } from "../../services/auth-service";
import './Register.css';
import LogoImage from '../../assets/crickai.png';

const RegisterPage = () => {

    const history = useHistory();

    const [userEmail, setUserEmail] = useState("");
    const [password, setPassword] = useState("");
    const [username, setUsername] = useState("");


    const onSubmit = async (e) => {
        e.preventDefault();
        let data = {
            email: userEmail,
            password: password,
            username: username
        }

        const response = await register(data).then((res: any) => {
            let successData = res['data'];

            if (successData['message']) {
                alert(JSON.stringify(successData['message']));
            }

            if (successData.status === 200) {
                history.push('/login');
            }
        }).catch(function (error) {
            if (error) {
                console.log('error ', error);
            }
        });

        // console.log('response ', response);


    }

    return (
        <div className="h-100">
            <div className="ml-2">
                <img src={LogoImage} className="img-fluid logo-img" height="110px" width="110px" />
            </div>
            <div className="h-100 mb-3 d-flex justify-content-center align-items-center">
                <div className="p-5 w-35 card border-0 register-card">
                    <form>
                        <h5 className="text-center">Register</h5>
                        {/* <div className="mt-4 d-flex">
                            <div className="p-1">
                                <label className="form-label">First name</label>
                                <input type="number" className="form-control" />
                            </div>
                            <div className="p-1">
                                <label className="form-label">Last name</label>
                                <input type="number" className="form-control" />
                            </div>
                        </div> */}
                        <div className="mt-4">
                            <label className="form-label">Enter Username</label>
                            <input type="text"
                                className="form-control"
                                value={username}
                                name="username"
                                onChange={e => setUsername(e.target.value)} />
                        </div>
                        <div className="mt-4">
                            <label className="form-label">Email Address</label>
                            <input
                                type="text"
                                className="form-control"
                                value={userEmail}
                                name="userEmail"
                                onChange={e => setUserEmail(e.target.value)} />
                        </div>
                        <div className="mt-4">
                            <label className="form-label">Enter your Password</label>
                            <input
                                type="password"
                                className="form-control"
                                name="userpassword"
                                value={password}
                                onChange={e => setPassword(e.target.value)} />
                        </div>
                        <div className="mt-4">
                            {/* <Link to='/companyRegister'> */}
                            <button type="button" className="w-100 btn btn-primary" onClick={onSubmit}>Register</button>
                            {/* </Link> */}
                        </div>
                        <div className="mt-3 w-100 d-flex flex-column justify-content-end align-items-end">
                            <Link to="/login">
                                <span className="register__login-text text-decoration-none">Login</span>
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    )

}


export default RegisterPage;

