// export const BACKEND_URL = "https://lco-server.herokuapp.com/";
// export const WS_BACKEND_URL = "wss://lco-server.herokuapp.com/";
// // "wss://overlay-dot-wowzalivestreaming.uc.r.appspot.com/";

export const BACKEND_URL = "https://ico-server-gql.in.techath.com/";
export const WS_BACKEND_URL = "wss://ico-server-gql.in.techath.com/";

export const API_URL = "https://crickai-be.in.techath.com/";


