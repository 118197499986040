import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Match = {
  __typename?: 'Match';
  batsmanOne?: Maybe<Scalars['String']>;
  batsmanOneScore?: Maybe<Scalars['Float']>;
  batsmanTwo?: Maybe<Scalars['String']>;
  batsmanTwoScore?: Maybe<Scalars['Float']>;
  bowler?: Maybe<Scalars['String']>;
  cameraId?: Maybe<Scalars['String']>;
  ccClubId?: Maybe<Scalars['String']>;
  ccSeriesId?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  fixtureId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  location?: Maybe<Scalars['String']>;
  matchDate?: Maybe<Scalars['String']>;
  matchEnded?: Maybe<Scalars['Boolean']>;
  matchName?: Maybe<Scalars['String']>;
  matchStarted?: Maybe<Scalars['Boolean']>;
  matchTime?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  noTimeLimit?: Maybe<Scalars['Boolean']>;
  overlay?: Maybe<Scalars['String']>;
  overlayManager?: Maybe<Scalars['String']>;
  scrapingLink?: Maybe<Scalars['String']>;
  showFullScoreboard?: Maybe<Scalars['Boolean']>;
  showFullScreenStatus?: Maybe<Scalars['Boolean']>;
  showLogo?: Maybe<Scalars['Boolean']>;
  showScoresOverlay?: Maybe<Scalars['Boolean']>;
  showStatus?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  switchTeams?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  youtubeUrl?: Maybe<Scalars['String']>;
};

export type MatchDetailsInput = {
  batsmanOne?: Maybe<Scalars['String']>;
  batsmanOneScore?: Maybe<Scalars['Float']>;
  batsmanTwo?: Maybe<Scalars['String']>;
  batsmanTwoScore?: Maybe<Scalars['Float']>;
  bowler?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  matchDate?: Maybe<Scalars['String']>;
  matchName?: Maybe<Scalars['String']>;
  matchStarted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  noTimeLimit?: Maybe<Scalars['Boolean']>;
  overlay?: Maybe<Scalars['String']>;
  overlayManager?: Maybe<Scalars['String']>;
  scrapingLink?: Maybe<Scalars['String']>;
  showFullScoreboard?: Maybe<Scalars['Boolean']>;
  showFullScreenStatus?: Maybe<Scalars['Boolean']>;
  showLogo?: Maybe<Scalars['Boolean']>;
  showScoresOverlay?: Maybe<Scalars['Boolean']>;
  showStatus?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  switchTeams?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
  youtubeUrl?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createMatch?: Maybe<Match>;
  createNewMatch?: Maybe<Match>;
  updateMatchDetails?: Maybe<Match>;
};


export type MutationCreateMatchArgs = {
  url: Scalars['String'];
};


export type MutationUpdateMatchDetailsArgs = {
  matchDetailsInput: MatchDetailsInput;
};

export type Query = {
  __typename?: 'Query';
  getAllMatches?: Maybe<Array<Match>>;
  match?: Maybe<Match>;
};


export type QueryMatchArgs = {
  id: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  subscribeMatchDetails: Match;
};

export type UpdateMatchDetailsMutationVariables = Exact<{
  options: MatchDetailsInput;
}>;


export type UpdateMatchDetailsMutation = (
  { __typename?: 'Mutation' }
  & { updateMatchDetails?: Maybe<(
    { __typename?: 'Match' }
    & Pick<Match, 'id' | 'url'>
  )> }
);

export type CreateNewMatchMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateNewMatchMutation = (
  { __typename?: 'Mutation' }
  & { createNewMatch?: Maybe<(
    { __typename?: 'Match' }
    & Pick<Match, 'id'>
  )> }
);

export type GetAllMatchesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllMatchesQuery = (
  { __typename?: 'Query' }
  & { getAllMatches?: Maybe<Array<(
    { __typename?: 'Match' }
    & Pick<Match, 'id' | 'url' | 'showLogo' | 'batsmanOne' | 'batsmanTwo' | 'batsmanOneScore' | 'batsmanTwoScore' | 'bowler' | 'showScoresOverlay' | 'showFullScoreboard' | 'status' | 'switchTeams' | 'youtubeUrl' | 'overlay' | 'overlayManager' | 'matchDate' | 'matchName' | 'scrapingLink'>
  )>> }
);

export type MatchQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type MatchQuery = (
  { __typename?: 'Query' }
  & { match?: Maybe<(
    { __typename?: 'Match' }
    & Pick<Match, 'id' | 'url' | 'showLogo' | 'batsmanOne' | 'batsmanTwo' | 'batsmanOneScore' | 'batsmanTwoScore' | 'bowler' | 'showScoresOverlay' | 'showFullScoreboard' | 'status' | 'showStatus' | 'switchTeams' | 'youtubeUrl' | 'overlay' | 'overlayManager' | 'matchDate' | 'matchName' | 'scrapingLink' | 'noTimeLimit' | 'location' | 'showFullScreenStatus' | 'matchStarted'>
  )> }
);

export type SubscribeMatchDetailsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type SubscribeMatchDetailsSubscription = (
  { __typename?: 'Subscription' }
  & { subscribeMatchDetails: (
    { __typename?: 'Match' }
    & Pick<Match, 'id' | 'url' | 'showLogo' | 'batsmanOne' | 'batsmanTwo' | 'batsmanOneScore' | 'batsmanTwoScore' | 'bowler' | 'showScoresOverlay' | 'showFullScoreboard' | 'status' | 'showStatus'>
  ) }
);


export const UpdateMatchDetailsDocument = gql`
    mutation UpdateMatchDetails($options: MatchDetailsInput!) {
  updateMatchDetails(matchDetailsInput: $options) {
    id
    url
  }
}
    `;
export type UpdateMatchDetailsMutationFn = Apollo.MutationFunction<UpdateMatchDetailsMutation, UpdateMatchDetailsMutationVariables>;

/**
 * __useUpdateMatchDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateMatchDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMatchDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMatchDetailsMutation, { data, loading, error }] = useUpdateMatchDetailsMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useUpdateMatchDetailsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMatchDetailsMutation, UpdateMatchDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMatchDetailsMutation, UpdateMatchDetailsMutationVariables>(UpdateMatchDetailsDocument, options);
      }
export type UpdateMatchDetailsMutationHookResult = ReturnType<typeof useUpdateMatchDetailsMutation>;
export type UpdateMatchDetailsMutationResult = Apollo.MutationResult<UpdateMatchDetailsMutation>;
export type UpdateMatchDetailsMutationOptions = Apollo.BaseMutationOptions<UpdateMatchDetailsMutation, UpdateMatchDetailsMutationVariables>;
export const CreateNewMatchDocument = gql`
    mutation CreateNewMatch {
  createNewMatch {
    id
  }
}
    `;
export type CreateNewMatchMutationFn = Apollo.MutationFunction<CreateNewMatchMutation, CreateNewMatchMutationVariables>;

/**
 * __useCreateNewMatchMutation__
 *
 * To run a mutation, you first call `useCreateNewMatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewMatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewMatchMutation, { data, loading, error }] = useCreateNewMatchMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateNewMatchMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewMatchMutation, CreateNewMatchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNewMatchMutation, CreateNewMatchMutationVariables>(CreateNewMatchDocument, options);
      }
export type CreateNewMatchMutationHookResult = ReturnType<typeof useCreateNewMatchMutation>;
export type CreateNewMatchMutationResult = Apollo.MutationResult<CreateNewMatchMutation>;
export type CreateNewMatchMutationOptions = Apollo.BaseMutationOptions<CreateNewMatchMutation, CreateNewMatchMutationVariables>;
export const GetAllMatchesDocument = gql`
    query GetAllMatches {
  getAllMatches {
    id
    url
    showLogo
    batsmanOne
    batsmanTwo
    batsmanOneScore
    batsmanTwoScore
    bowler
    showScoresOverlay
    showFullScoreboard
    status
    switchTeams
    youtubeUrl
    overlay
    overlayManager
    matchDate
    matchName
    scrapingLink
  }
}
    `;

/**
 * __useGetAllMatchesQuery__
 *
 * To run a query within a React component, call `useGetAllMatchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllMatchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllMatchesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllMatchesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllMatchesQuery, GetAllMatchesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllMatchesQuery, GetAllMatchesQueryVariables>(GetAllMatchesDocument, options);
      }
export function useGetAllMatchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllMatchesQuery, GetAllMatchesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllMatchesQuery, GetAllMatchesQueryVariables>(GetAllMatchesDocument, options);
        }
export type GetAllMatchesQueryHookResult = ReturnType<typeof useGetAllMatchesQuery>;
export type GetAllMatchesLazyQueryHookResult = ReturnType<typeof useGetAllMatchesLazyQuery>;
export type GetAllMatchesQueryResult = Apollo.QueryResult<GetAllMatchesQuery, GetAllMatchesQueryVariables>;
export const MatchDocument = gql`
    query Match($id: String!) {
  match(id: $id) {
    id
    url
    showLogo
    batsmanOne
    batsmanTwo
    batsmanOneScore
    batsmanTwoScore
    bowler
    showScoresOverlay
    showFullScoreboard
    status
    showStatus
    switchTeams
    youtubeUrl
    overlay
    overlayManager
    matchDate
    matchName
    scrapingLink
    noTimeLimit
    location
    showFullScreenStatus
    matchStarted
  }
}
    `;

/**
 * __useMatchQuery__
 *
 * To run a query within a React component, call `useMatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useMatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMatchQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMatchQuery(baseOptions: Apollo.QueryHookOptions<MatchQuery, MatchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MatchQuery, MatchQueryVariables>(MatchDocument, options);
      }
export function useMatchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MatchQuery, MatchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MatchQuery, MatchQueryVariables>(MatchDocument, options);
        }
export type MatchQueryHookResult = ReturnType<typeof useMatchQuery>;
export type MatchLazyQueryHookResult = ReturnType<typeof useMatchLazyQuery>;
export type MatchQueryResult = Apollo.QueryResult<MatchQuery, MatchQueryVariables>;
export const SubscribeMatchDetailsDocument = gql`
    subscription SubscribeMatchDetails {
  subscribeMatchDetails {
    id
    url
    showLogo
    batsmanOne
    batsmanTwo
    batsmanOneScore
    batsmanTwoScore
    bowler
    showScoresOverlay
    showFullScoreboard
    status
    showStatus
  }
}
    `;

/**
 * __useSubscribeMatchDetailsSubscription__
 *
 * To run a query within a React component, call `useSubscribeMatchDetailsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeMatchDetailsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeMatchDetailsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSubscribeMatchDetailsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<SubscribeMatchDetailsSubscription, SubscribeMatchDetailsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeMatchDetailsSubscription, SubscribeMatchDetailsSubscriptionVariables>(SubscribeMatchDetailsDocument, options);
      }
export type SubscribeMatchDetailsSubscriptionHookResult = ReturnType<typeof useSubscribeMatchDetailsSubscription>;
export type SubscribeMatchDetailsSubscriptionResult = Apollo.SubscriptionResult<SubscribeMatchDetailsSubscription>;