import React, { useEffect, useState } from "react";
import { Row, Col, Switch, Checkbox } from "antd";
import styled from "styled-components";
import { useUpdateMatchDetailsMutation } from "../generated/graphql";

interface FullScoreBoardSwitchProps {
  matchId: string;
  showFullScoreboard: boolean;
  noTimeLimit: boolean;
}

const SwtichControl = styled.div`
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: rgba(211, 211, 211, 0.5);
`;

export const FullScoreBoardSwitch: React.FC<FullScoreBoardSwitchProps> = ({
  matchId,
  showFullScoreboard,
  noTimeLimit,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [noTimeLimitChecked, setNoTimeLimitChecked] = useState(false);
  const [updateMatchDetails] = useUpdateMatchDetailsMutation();
  const handleEventChange = async () => {
    await updateMatchDetails({
      variables: {
        options: {
          id: matchId,
          showFullScoreboard: !isChecked,
        },
      },
    });
    setIsChecked(!isChecked);
  };

  const handleTimeLimitEventChange = async () => {
    await updateMatchDetails({
      variables: {
        options: {
          id: matchId,
          noTimeLimit: !noTimeLimitChecked,
        },
      },
    });
    setNoTimeLimitChecked(!noTimeLimitChecked);
  };

  function onChange(checked) {
    setIsChecked(checked);
    handleEventChange();
  }

  function onTimeLimitChange(checked) {
    setNoTimeLimitChecked(checked);
    handleTimeLimitEventChange();
  }

  useEffect(() => {
    setIsChecked(showFullScoreboard);
    setNoTimeLimitChecked(noTimeLimit);
  }, [showFullScoreboard]);

  useEffect(() => {
    setNoTimeLimitChecked(noTimeLimit);
  }, [noTimeLimit]);

  return (
    <SwtichControl>
      <Row>
        <Col span={16}>Full Scoreboard overlay</Col>
        <Col span={20}>
          <Checkbox checked={noTimeLimitChecked} onChange={onTimeLimitChange}>
            <b>No Time Limit</b>
          </Checkbox>
        </Col>
        <Col span={4}>
          <Switch checked={isChecked} onChange={onChange} />
        </Col>
      </Row>
    </SwtichControl>
  );
};
