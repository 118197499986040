import React, { useState, useEffect } from "react";
import {
  Button,
  Row,
  Col,
  Input,
  Space,
  Tabs,
  Typography,
  Modal,
  message,
} from "antd";
import {
  getOverlayMatchById,
  updateMatch,
  checkForMatchId,
} from "../services/today-match-service";
import { gql } from "@apollo/client";
import { useSubscription } from "@apollo/client/react";
import { getCameras, updateCameraInfo } from "../services/camera-service";
import { Match } from "../generated/graphql";
import {
  CameraOutlined,
  CopyOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import {
  ScoreboardSwitch,
  FullScoreBoardSwitch,
  LogoSwitch,
  MatchStatus,
  TeamSwitch,
  ShowStatusSwitch,
  Wrapper,
} from "../components";
import { Link } from "react-router-dom";
import { createNewStream, stopBroadcast } from "../services/youtube-service";
import { MatchDetailsInput } from "../generated/graphql";
import moment from "moment";

const { TabPane } = Tabs;
const { Text } = Typography;

const MaterialContainer = styled.div`
  padding: 10px;
  z-index: 10000000;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
`;

interface OverlayManagerProps {}

interface Camera {
  cameraName: string;
  cameraType: string;
  isActive: boolean;
  _id: string;
  matchId: string;
  streamId: string;
  streamUrl: string;
  location: string;
}

const SubscribeMatchDetails = gql`
  subscription SubscribeMatchDetails {
    subscribeMatchDetails {
      id
      url
      showLogo
      batsmanOne
      batsmanTwo
      batsmanOneScore
      batsmanTwoScore
      bowler
      showScoresOverlay
      showFullScoreboard
      status
      showStatus
      switchTeams
      youtubeUrl
      overlay
      overlayManager
      matchDate
      matchName
      scrapingLink
      location
      noTimeLimit
      showFullScreenStatus
      matchStarted
    }
  }
`;

export const OverlayManager: React.FC<OverlayManagerProps> = ({}) => {
  const [cameras, setCameras] = useState<Camera[]>([]);
  const [cameraId, setCameraId] = useState<string>();
  const [managerData, setManagerData] = useState<Match>();
  const [matchId, setMatchId] = useState<string>();

  const [subscribeMatchDetails, setSubscribeMatchDetails] =
    useState<MatchDetailsInput>();

  const { data, loading } = useSubscription(SubscribeMatchDetails);

  useEffect(() => {
    document.body.style.background = "none";
    if (!loading && data?.subscribeMatchDetails) {
      const camera = cameras.find((camera) => camera._id === cameraId);
      if (data?.subscribeMatchDetails?.id === camera.matchId) {
        setManagerData(data?.subscribeMatchDetails);
      }
    }
    console.log(loading);
    console.log(data);
    console.log(subscribeMatchDetails);
  }, [data]);

  useEffect(() => {
    const cameras = async () => {
      try {
        const response = await getCameras();
        setCameras(response?.data);
        setCameraId(response?.data[0]._id);
      } catch (error) {}
    };
    cameras();
  }, [1]);

  useEffect(() => {
    const overlayMatchData = async () => {
      const camera = cameras.find((camera) => camera._id === cameraId);
      if (camera?.matchId && camera.matchId.trim() !== "") {
        try {
          setMatchId(camera.matchId);
          const response = await getOverlayMatchById(camera.matchId);
          setManagerData((managerData) => (managerData = response?.data[0]));
        } catch (error) {}
      }
    };
    overlayMatchData();
  }, [cameraId]);

  const confirm = () => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content:
        "Unassigning match will stop streaming and remove from the camera",
      okText: "Ok",
      cancelText: "Cancel",
      onOk: () => {
        handleOk();
      },
    });
  };

  const confirmAllMatchesUnassign = () => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content:
        "Unassigning all matches will stop streaming and remove from the cameras",
      okText: "Ok",
      cancelText: "Cancel",
      onOk: () => {
        handleUnassignAllMatches();
      },
    });
  };

  const handleStartStream = (e: any) => {
    const cameraFiltered = cameras.find((camera) => camera._id === cameraId);
    const createNewStreamApi = async () => {
      const payload = {
        broadcasttitle: managerData.matchName,
        broadcastdescription: "A CricAI streaming...",
        privacystatus: "public",
        username: "sureshd",
        matchId: cameraFiltered.matchId,
        streamId: cameraFiltered.streamId,
      };
      try {
        const streamResponse = await createNewStream(payload);
        const data = { ...managerData };
        data.youtubeUrl = streamResponse.data.url;
        await updateMatch(data);
        const response = await getOverlayMatchById(cameraFiltered.matchId);
        setManagerData(response?.data[0]);
        const match = response?.data[0];
        await checkForMatchId({
          clubId: match?.ccClubId,
          seriesId: match?.ccSeriesId,
          fixtureId: match?.fixtureId,
          matchDate: match?.matchDate,
        });
      } catch (error) {
        console.log(error);
        message.error(error?.message);
      }
    };
    createNewStreamApi();
  };

  const handleStopStream = (e: any) => {
    confirm();
  };

  const handleOk = async () => {
    const cameraFiltered = cameras.find((camera) => camera._id === cameraId);
    let match = { ...managerData };
    try {
      if (match.youtubeUrl && match.youtubeUrl.trim() !== "") {
        const youtube = match.youtubeUrl.split("/");
        const broadcastId = youtube[youtube.length - 1];
        await stopBroadcast({ broadcastId });
      }
      let response = await getOverlayMatchById(cameraFiltered.matchId);
      match = response?.data[0];
      match.cameraId = null;
      match.matchEnded = true;
      await updateMatch(match);
      cameraFiltered.matchId = null;
      response = await updateCameraInfo(cameraFiltered);
      setCameras(response?.data);
      message.success("Stopped streamed and unassigned the match!");
    } catch (error) {}
  };

  const handleUnassignAllMatches = () => {
    cameras.forEach(async (camera) => {
      if (camera?.matchId && camera.matchId.trim() !== "") {
        try {
          let response = await getOverlayMatchById(camera.matchId);
          const match = response?.data[0];
          match.cameraId = null;
          match.matchEnded = true;
          if (match.youtubeUrl && match.youtubeUrl.trim() !== "") {
            const youtube = match.youtubeUrl.split("/");
            const broadcastId = youtube[youtube.length - 1];
            await stopBroadcast({ broadcastId });
          }
          await updateMatch(match);
          camera.matchId = null;
          response = await updateCameraInfo(camera);
          setCameras(response?.data);
          message.success("Stopped streaming and unassigned all the matches!");
        } catch (error) {}
      }
    });
  };

  const callback = (key: string) => {
    setCameraId(key);
  };
  return (
    <>
      {cameras.filter((camera) => camera.matchId !== null).length ? (
        <Button
          type="primary"
          danger
          style={{
            margin: "0 0 10px 0",
            float: "right",
          }}
          onClick={(e: any) => confirmAllMatchesUnassign()}
        >
          Unassign All Matches
        </Button>
      ) : null}

      <Tabs
        defaultActiveKey={cameraId}
        onChange={callback}
        style={{ padding: "0 0 10px 10px" }}
        type="card"
      >
        {cameras &&
          cameras.map((camera, i) => (
            <TabPane
              tab={
                <span>
                  <CameraOutlined />
                  {camera.cameraName}
                </span>
              }
              key={camera._id}
            >
              {camera?.matchId && camera.matchId.trim() !== "" ? (
                <>
                  <Row>
                    <Col span={20}>
                      <Space>
                        <Text strong>Match Name:</Text>
                        <Text>{managerData?.matchName}</Text>
                        <Text strong>Location:</Text>
                        <Text>{camera?.location}</Text>
                      </Space>
                    </Col>
                    <Col span={4}>
                      <Button
                        type="primary"
                        danger
                        onClick={(e: any) => confirm()}
                      >
                        Unassign Match
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={7} xs={24} sm={7}>
                      <Wrapper variant="small">
                        <Text strong>Overlay Manager</Text>

                        <MaterialContainer>
                          <LogoSwitch
                            matchId={matchId}
                            showLogo={managerData?.showLogo}
                          />
                          <ScoreboardSwitch
                            matchId={matchId}
                            showScoresOverlay={managerData?.showScoresOverlay}
                          />
                          <ShowStatusSwitch
                            matchId={matchId}
                            showStatus={managerData?.showStatus}
                            showFullScreenStatus={
                              managerData?.showFullScreenStatus
                            }
                          />
                          <TeamSwitch
                            matchId={matchId}
                            switchTeams={managerData?.switchTeams}
                          />
                          <FullScoreBoardSwitch
                            matchId={matchId}
                            showFullScoreboard={managerData?.showFullScoreboard}
                            noTimeLimit={managerData?.noTimeLimit}
                          />
                          <MatchStatus matchId={matchId} />
                        </MaterialContainer>
                      </Wrapper>
                    </Col>
                    <Col span={1} xs={24} sm={1}></Col>
                    <Col span={12} xs={24} sm={12}>
                      <Wrapper variant="small">
                        <Text strong>Youtube options</Text>
                        <MaterialContainer>
                          <Space direction="vertical">
                            <Space>
                              <Button onClick={handleStartStream}>
                                Start Stream
                              </Button>
                              <Button onClick={handleStopStream}>
                                Stop Stream
                              </Button>
                              <Button>Show on website</Button>
                            </Space>
                            <div>
                              Youtube URL
                              <Input
                                addonAfter={
                                  <CopyOutlined
                                    onClick={(e: any) =>
                                      navigator.clipboard.writeText(
                                        managerData?.youtubeUrl
                                      )
                                    }
                                  />
                                }
                                value={managerData?.youtubeUrl}
                              />
                            </div>
                          </Space>
                        </MaterialContainer>
                      </Wrapper>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Text strong>
                    No Match Assigned to this overlay manager. Go to{" "}
                    <Link to="/app/">Today Matches</Link>
                  </Text>
                </>
              )}
            </TabPane>
          ))}
      </Tabs>
    </>
  );
};
