import React from "react";
import "./App.less";
import CustomLayout from "./layout/Layout";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { ApolloProvider, InMemoryCache, ApolloClient } from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { split, HttpLink } from "@apollo/client";
import { getMainDefinition } from "apollo-utilities";
import { BACKEND_URL, WS_BACKEND_URL } from "./constants";
import OverlayOne from "./pages/OverlayOne";
import OverlayTwo from "./pages/OverlayTwo";
import OverlayThree from "./pages/OverlayThree";
import OverlayFour from "./pages/OverlayFour";
import OverlayFive from "./pages/OverlayFive";
import LoginPage from "./pages/Login/Login";
import RegisterPage from "./pages/Register/Register";
import CompanyRegisterPage from "./pages/Company/Register/Register";

const httpLink = new HttpLink({
  uri: BACKEND_URL + "graphql",
});

const wsLink = new WebSocketLink({
  uri: `${WS_BACKEND_URL}graphql`,
  options: {
    reconnect: true,
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const client = new ApolloClient({
  link: splitLink,
  credentials: "include",
  cache: new InMemoryCache(),
});

function App() {
  return (
    <>
      <ApolloProvider client={client}>
        <Router>
          <Switch>
            <Redirect exact from="/" to="login" />
            <Route path="/app/">
              <CustomLayout />
            </Route>
            <Route path="/camera1">
              <OverlayOne />
            </Route>
            <Route path="/camera2">
              <OverlayTwo />
            </Route>
            <Route path="/camera3">
              <OverlayThree />
            </Route>
            <Route path="/camera4">
              <OverlayFour />
            </Route>
            <Route path="/camera5">
              <OverlayFive />
            </Route>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/register">
              <RegisterPage />
            </Route>
            <Route path="/companyRegister">
              <CompanyRegisterPage />
            </Route>
          </Switch>
        </Router>
      </ApolloProvider>
    </>
  );
}

export default App;
