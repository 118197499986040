import { Button, FormLabel, Input } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import { useUpdateMatchDetailsMutation } from "../generated/graphql";

interface PlayersScoresFormProps {}

const FromStyleWrapper = styled.div`
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: rgba(211, 211, 211, 0.3);
`;

const FlexCol = styled.div`
  margin-top: 10px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
`;

const NameInputStyleWrapper = styled.div`
  width: 75%;
  margin-right: 10px;
`;

const ScoreInputStyleWrapper = styled.div`
  width: 20%;
`;

export const PlayersScoresForm: React.FC<PlayersScoresFormProps> = ({}) => {
  const [batsmanOne, setBatsmanOne] = useState<string>("");
  const [batsmanTwo, setBatsmanTwo] = useState<string>("");
  const [batsmanOneScore, setBatsmanOneScore] = useState<number>();
  const [batsmanTwoScore, setBatsmanTwoScore] = useState<number>();
  const [updateMatchDetails] = useUpdateMatchDetailsMutation();

  const updatePlayerDetails = async () => {
    await updateMatchDetails({
      variables: {
        options: {
          id: "1",
          batsmanOne,
          batsmanTwo,
          batsmanOneScore,
          batsmanTwoScore,
        },
      },
    });
  };

  return (
    <FromStyleWrapper>
      <FormLabel>Current Batsmen Scores</FormLabel>
      <FlexCol>
        <NameInputStyleWrapper>
          <Input
            placeholder="Batsman 1 Name"
            onChange={(e) => {
              setBatsmanOne(e.target.value);
            }}
          />
        </NameInputStyleWrapper>
        <ScoreInputStyleWrapper>
          <Input
            placeholder="score"
            type="number"
            onChange={(e) => {
              setBatsmanOneScore(+e.target.value);
            }}
          />
        </ScoreInputStyleWrapper>
      </FlexCol>
      <FlexCol>
        <NameInputStyleWrapper>
          <Input
            placeholder="Batsman 2 Name"
            onChange={(e) => {
              setBatsmanTwo(e.target.value);
            }}
          />
        </NameInputStyleWrapper>
        <ScoreInputStyleWrapper>
          <Input
            placeholder="score"
            type="number"
            onChange={(e) => {
              setBatsmanTwoScore(+e.target.value);
            }}
          />
        </ScoreInputStyleWrapper>
      </FlexCol>
      <Button onClick={() => updatePlayerDetails()}>Update</Button>
    </FromStyleWrapper>
  );
};
