import { Button, Input, notification } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import { useUpdateMatchDetailsMutation } from "../generated/graphql";

interface MatchStatusProps {
  matchId: string;
}

const FromStyleWrapper = styled.div`
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: rgba(211, 211, 211, 0.3);
`;

const FlexCol = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
`;

const StatuStyleWrapper = styled.button`
    font-size: small;
    padding: 10px;
    width:auto;
    height:50px
    background-color: white;
    margin-right: 15px;
    border: 1px solid #3182ce;
    border-radius: 5px;
    display: flex;
    text-align: center;
`;

const NameInputStyleWrapper = styled.div`
  width: 75%;
  margin-right: 10px;
`;

export const MatchStatus: React.FC<MatchStatusProps> = ({ matchId }) => {
  const [customStatus, setCustomStatus] = useState<string>("");
  const [updateMatchDetails] = useUpdateMatchDetailsMutation();

  const updateStatus = async (status: string) => {
    await updateMatchDetails({
      variables: {
        options: {
          id: matchId,
          status,
        },
      },
    })
      .then((response) => {
        notification["success"]({
          message: "Status updated successfully",
        });
        return;
      })
      .catch((error) => {
        notification["error"]({
          message: "Error in updating Status",
        });
      });
    setCustomStatus("");
  };
  return (
    <FromStyleWrapper>
      Match Status
      <FlexCol>
        <Button
          onClick={() => {
            updateStatus("Innings Break");
          }}
        >
          Innings Break
        </Button>
        <Button
          onClick={() => {
            updateStatus("Match will start soon..");
          }}
        >
          Match Start
        </Button>
        <Button
          onClick={() => {
            updateStatus("Strategic timeout");
          }}
        >
          Strategic timeout
        </Button>
      </FlexCol>
      <FlexCol>
        <NameInputStyleWrapper>
          <Input
            placeholder="Custom status"
            value={customStatus}
            onChange={(e) => {
              setCustomStatus(e.target.value);
            }}
          />
        </NameInputStyleWrapper>
        <Button
          type="primary"
          onClick={() => {
            updateStatus(customStatus);
          }}
        >
          Update Status
        </Button>
      </FlexCol>
    </FromStyleWrapper>
  );
};
