import React, { useState, useEffect } from "react";
import { Button, Row, Form, Input, Tabs } from "antd";
import { getCameras, updateCameraInfo } from "../../services/camera-service";
import { CameraOutlined, CopyOutlined } from "@ant-design/icons";
import { Wrapper } from "../../components";
import "./Camera.less";

const { TabPane } = Tabs;

interface CameraProps {}

interface CameraType {
  cameraName: string;
  cameraType: string;
  isActive: boolean;
  _id: string;
  matchId: string;
  streamId: string;
  streamUrl: string;
  location: string;
  streamKey: string;
}

export const Camera: React.FC<CameraProps> = ({}) => {
  const [form] = Form.useForm();
  const [cameras, setCameras] = useState<CameraType[]>([]);
  const [cameraId, setCameraId] = useState<string>();
  const [location, setLocation] = useState<string>();

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
  };

  useEffect(() => {
    const cameras = async () => {
      try {
        const response = await getCameras();
        setCameras(response?.data);
        setCameraId(response?.data[0]._id);
      } catch (error) {}
    };
    cameras();
  }, []);

  useEffect(() => {
    const cameraFiltered = cameras.find((camera) => camera._id === cameraId);
    form.setFieldsValue({
      cameraName: cameraFiltered?.cameraName,
      matchId: cameraFiltered?.matchId,
      location: cameraFiltered?.location,
      streamId: cameraFiltered?.streamId,
      streamUrl: cameraFiltered?.streamUrl,
      streamKey: cameraFiltered?.streamKey,
    });
  }, [cameraId]);

  const callback = (key: string) => {
    setCameraId(key);
  };

  const handleSaveChanges = async () => {
    const cameraFiltered = cameras.find((camera) => camera._id === cameraId);
    cameraFiltered.location = location;
    try {
      const response = await updateCameraInfo(cameraFiltered);
      setCameras(response?.data);
    } catch (error) {}
  };

  return (
    <>
      <Tabs defaultActiveKey={cameraId} onChange={callback}>
        {cameras &&
          cameras.map((camera, i) => (
            <TabPane
              tab={
                <span>
                  <CameraOutlined />
                  {camera.cameraName}
                </span>
              }
              key={camera._id}
            >
              <Wrapper variant="small">
                <Row gutter={16}>
                  <Form form={form} {...formItemLayout}>
                    <Form.Item label="Camera Name" name="cameraName">
                      <Input placeholder="input placeholder" disabled />
                    </Form.Item>
                    <Form.Item label="Match Id" name="matchId">
                      <Input placeholder="input placeholder" disabled />
                    </Form.Item>
                    <Form.Item label="Location" name="location">
                      <Input
                        placeholder="Enter location.."
                        onChange={(e: any) => setLocation(e.target.value)}
                      />
                    </Form.Item>
                    <Form.Item label="Stream Id" name="streamId">
                      <Input
                        addonAfter={
                          <CopyOutlined
                            onClick={(e: any) =>
                              navigator.clipboard.writeText(camera?.streamId)
                            }
                          />
                        }
                        placeholder="input placeholder"
                        disabled
                      />
                    </Form.Item>
                    <Form.Item label="Stream URL" name="streamUrl">
                      <Input
                        addonAfter={
                          <CopyOutlined
                            onClick={(e: any) =>
                              navigator.clipboard.writeText(camera?.streamUrl)
                            }
                          />
                        }
                        placeholder="input placeholder"
                        disabled
                      />
                    </Form.Item>
                    <Form.Item label="Stream Key" name="streamKey">
                      <Input
                        addonAfter={
                          <CopyOutlined
                            onClick={(e: any) =>
                              navigator.clipboard.writeText(camera?.streamKey)
                            }
                          />
                        }
                        disabled
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        onClick={(e: any) => handleSaveChanges()}
                      >
                        Save changes
                      </Button>
                    </Form.Item>
                  </Form>
                </Row>
              </Wrapper>
            </TabPane>
          ))}
      </Tabs>
    </>
  );
};
