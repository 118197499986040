import React from "react";
import styled from "styled-components";
import cricaiLogo from "../assets/crickai.png";
import sccaLogo from "../assets/scca.jpeg";

const CricAiLogo = styled.div`
  font-weight: bold;
  width: 80px;
  height: 50px;
  position: absolute;
  padding: 2px 15px;
  top: 20px;
  left: 60px;
  display: flex;
  flex-direction: row;
  background-color: white;
  z-index: 10000000;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
`;

const SccaLogo = styled.div`
  font-weight: bold;
  width: auto;
  height: 50px;
  position: absolute;
  top: 20px;
  right: 60px;
  display: flex;
  flex-direction: row;
  background-color: white;
  z-index: 10000000;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
`;
export const Logo: React.FC<{}> = ({}) => {
  return (
    <>
      <CricAiLogo>
        <img src={cricaiLogo} width="50" height="50" />
      </CricAiLogo>
      <SccaLogo>
        <img src={sccaLogo} width="130" height="100" />
      </SccaLogo>
    </>
  );
};
