import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { login } from "../../services/auth-service";
import './Login.css';
import LogoImage from '../../assets/crickai.png';

const LoginPage = () => {

    const history = useHistory();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");


    const onSubmit = async (e) => {
        e.preventDefault();
        let data = {
            email: email,
            password: password
        }

        const response = await login(data);

        let userData = response['data'];

        if (userData.status === 200) {
            sessionStorage.setItem('userData', JSON.stringify(userData));
            history.push('/app');
        }
    }

    return (
        <div className="h-100">
            <img src={LogoImage} className="img-fluid logo-img" height="110px" width="110px" />
            <div className="h-100 d-flex justify-content-center align-items-center">
                <div className="p-5 w-35 card border-0 login-card">
                    <form>
                        <h5 className="text-center">Login</h5>
                        <div className="mt-4">
                            <label className="form-label">Enter your Email</label>
                            <input type="text"
                                className="form-control"
                                value={email}
                                onChange={e => setEmail(e.target.value)} />
                        </div>
                        <div className="mt-4">
                            <label className="form-label">Enter your Password</label>
                            <input type="password"
                                className="form-control"
                                value={password}
                                onChange={e => setPassword(e.target.value)} />
                        </div>
                        <div className="mt-4">
                            <button type="button" className="w-100 btn btn-primary" onClick={onSubmit} >Login</button>
                        </div>
                        <div className="mt-3 w-100 d-flex flex-column justify-content-end align-items-end">
                            <span className="login__forgot-password">Forgot Password?</span>
                            <Link to="/register">
                                <span className="login__forgot-password text-decoration-none">Register</span>
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    )

}


export default LoginPage;

