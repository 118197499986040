import React, { useState, createElement } from "react";
import { Layout, Menu, Breadcrumb, Typography, Space } from "antd";
import {
  UnorderedListOutlined,
  CameraOutlined,
  PlaySquareOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import "./Layout.less";
import { Route, Link, RouteComponentProps, withRouter } from "react-router-dom";
import { TodayMatches, Camera, OverlayManager } from "../pages";
import sampleImage from "../assets/crickai.png";
import CompanyRegisterPage from "../pages/Company/Register/Register";
import UpcomingMatchPage from "../pages/Match/UpcomingMatch/UpcomingMatch";
import MatchSummaryPage from "../pages/Match/MatchSummary/MatchSummary";

const { Header, Sider, Content } = Layout;
const { Title } = Typography;

const breadcrumbNameMap = new Map([
  ["/app/", "Today Matches"],
  ["/app/camera", "Cameras settings"],
  ["/app/overlaymanager", "Overlay Manager"],
  ["/app/matchSummary", "Match Summary"],
  ["/app/upcomingMatch", "Upcoming Matches"],
]);

interface CustomLayoutProps extends RouteComponentProps<any> { }

const CustomLayout: React.FC<CustomLayoutProps> = (props) => {
  const [collapsed, setCollapsed] = useState(false);

  const path = props.location.pathname;

  const toggle = () => {
    setCollapsed(!collapsed);
  };
  return (
    <>
      <Layout style={{ height: "100vh" }}>
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <div className="logo">
            <img
              src={sampleImage}
              width="64%"
              height="90%"
              style={{ marginTop: "0px", marginLeft: "18%" }}
            />
          </div>
          <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
            <Menu.Item key="1" icon={<UnorderedListOutlined />}>
              <Link to="/app/">Today Matches</Link>
            </Menu.Item>
            <Menu.Item key="2" icon={<PlaySquareOutlined />}>
              <Link to="/app/overlaymanager">Overlay Managers</Link>
            </Menu.Item>
            <Menu.Item key="3" icon={<CameraOutlined />}>
              <Link to="/app/camera">Cameras settings</Link>
            </Menu.Item>
            <Menu.Item key="4" icon={<CameraOutlined />}>
              <Link to="/app/upcomingMatch">Upcoming Matches</Link>
            </Menu.Item>
            <Menu.Item key="5" icon={<CameraOutlined />}>
              <Link to="/app/matchSummary">Match Summary</Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: 0 }}>
            <Space>
              {createElement(
                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: "trigger",
                  onClick: toggle,
                }
              )}
              <Title level={4} style={{ marginTop: "2px" }}>
                Camera Manager
              </Title>
            </Space>
          </Header>
          <Breadcrumb
            style={{
              padding: "10px 0 2px 20px",
            }}
          >
            <Breadcrumb.Item key={path}>
              <Link to={path}>{breadcrumbNameMap.get(path)}</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
          <Content
            className="site-layout-background"
            style={{
              margin: "10px 16px 24px 16px",
              padding: 24,
              minHeight: 500,
            }}
          >
            <Route path="/app/" exact>
              <TodayMatches />
            </Route>
            <Route path="/app/overlaymanager/" exact>
              <OverlayManager />
            </Route>
            <Route path="/app/camera/" exact>
              <Camera />
            </Route>
            <Route path="/app/upcomingMatch/" exact>
              <UpcomingMatchPage />
            </Route>
            <Route path="/app/matchSummary">
              <MatchSummaryPage />
            </Route>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default withRouter(CustomLayout);
