import React, { useState, useEffect } from "react";
import styled from "styled-components";
import statusImage from "../assets/status-image.jpeg";
import {
  getOverlayMatchById,
  updateMatch,
} from "../services/today-match-service";
import { useUpdateMatchDetailsMutation } from "../generated/graphql";

const StatusStyleWrapper = styled.div`
  font-weight: bold;
  width: auto;
  height: 50px;
  position: absolute;
  padding: 10px;
  top: 120px;
  right: 50px;
  display: flex;
  flex-direction: row;
  background-color: white;
  z-index: 10000000;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
`;

const Highlight = styled.span`
  color: #3182ce;
`;

const FullScreenHighlight = styled.div`
  color: white;
  z-index: 100;
  position: absolute;
  bottom: 15%;
  width: 100%;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
`;

const MatchName = styled.div`
  color: white;
  z-index: 100;
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 20%;
  font-size: 24px;
  font-weight: bold;
`;

interface MatchStatusOverlayProps {
  matchId: string;
  status: string | undefined;
  showFullScreenStatus: boolean;
  matchName: string | undefined;
  matchStarted: boolean;
}

export const MatchStatusOverlay: React.FC<MatchStatusOverlayProps> = ({
  status,
  showFullScreenStatus,
  matchName,
  matchId,
  matchStarted,
}) => {
  const [updateMatchDetails] = useUpdateMatchDetailsMutation();
  const [width, setWidth] = useState<Number>(document.body.clientWidth);
  const [height, setHeight] = useState<Number>(document.body.clientHeight);

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    let checkIfMatchStartedInterval;
    const overlayMatchData = () => {
      if (!matchStarted) {
        checkIfMatchStartedInterval = setInterval(async function () {
          try {
            const response = await getOverlayMatchById(matchId);
            const matchData = response?.data[0];
            if (
              matchData?.scrapingLink &&
              matchData?.scrapingLink.trim().length !== ""
            ) {
              await updateMatchDetails({
                variables: {
                  options: {
                    id: matchId,
                    showStatus: false,
                    showFullScreenStatus: false,
                    matchStarted: true,
                    showLogo: true,
                    showScoresOverlay: true,
                  },
                },
              });
            }
          } catch (error) {}
        }, 20000);
      }
    };
    overlayMatchData();
    return () => {
      if (checkIfMatchStartedInterval) {
        clearInterval(checkIfMatchStartedInterval);
      }
    };
  }, [matchId]);
  return (
    <>
      {showFullScreenStatus ? (
        <>
          <img
            src={statusImage}
            alt=""
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: `${width}px`,
              height: `${height}px`,
              zIndex: -1,
            }}
          />
          <MatchName>{matchName}</MatchName>
          <FullScreenHighlight>{status}</FullScreenHighlight>
        </>
      ) : (
        <StatusStyleWrapper>
          <Highlight>Match Status: </Highlight> {status}
        </StatusStyleWrapper>
      )}
    </>
  );
};
