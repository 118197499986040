import { Box } from "@mui/material";
import React from "react";

interface WrapperProps {
  variant?: "small" | "regular";
}

export const Wrapper: React.FC<WrapperProps> = ({
  children,
  variant = "regular",
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: variant === "regular" ? "800px" : "400px",
        marginLeft: "10px",
        marginRight: "10px",
      }}
    >
      {children}
    </Box>
  );
};
