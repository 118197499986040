import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CreateMatchPopup from "../CreateMatch/CreateMatch";
import './UpcomingMatch.css';
import ViewMatchPopup from "../ViewMatch/ViewMatch";
import { upcomingMatches } from "../../../services/today-match-service";

const UpcomingMatchPage = () => {


    const [modalShow, setModalShow] = useState(false);

    const [viewModalShow, setViewModalShow] = useState(false);

    const [upcomingMatchesData, setUpcomingMatchesData] = useState([]);


    useEffect(() => {
        getUpcomingMatches();
    }, [])

    const getUpcomingMatches = async () => {
        let data = {}

        const response = await upcomingMatches(data);

        setUpcomingMatchesData(response['data']);

    }


    return (
        <div className="p-0 m-0 container-fluid upcoming__match-parent">
            <div className="h-100">
                <div className="w-100 pb-2 d-flex justify-content-end bg-white">
                    <button type="button" className="btn btn-primary" onClick={() => setModalShow(true)}>CREATE MATCH</button>
                </div>
                {
                    upcomingMatchesData?.length !== 0 &&
                    <div className="w-100 card border-0 upcoming__match-card">
                        <div className="d-flex justify-content-between">
                            <div className="d-flex justify-content-center align-items-center">
                                <label className="p-1">Show</label>
                                <select className="p-1 form-control upcoming__match-pagination">
                                    <option>5</option>
                                    <option>10</option>
                                    <option>15</option>
                                    <option>20</option>
                                </select>
                                <span className="p-1">entries</span>
                            </div>
                            <div className="d-flex">
                                <label className="p-1">Search</label>
                                <input type="text" className="w-100 form-control" />
                            </div>
                        </div>
                        <div className="my-4">
                            <div>
                                <table className="table table-responsive">
                                    <thead>
                                        <tr>
                                            <th scope="col">Match Type</th>
                                            <th scope="col">Match ID</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Time</th>
                                            <th scope="col">Location</th>
                                            <th scope="col">View</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            upcomingMatchesData && upcomingMatchesData?.map((match, index) => {
                                                return (
                                                    <tr className="py-1" key={index}>
                                                        <th scope="row">Semi-finals</th>
                                                        <td>{match.ccMatchId}</td>
                                                        <td>{match.matchDate}</td>
                                                        <td>{match.matchTime}</td>
                                                        <td>{match.location}</td>
                                                        <td>
                                                            <a onClick={() => setViewModalShow(true)}>View</a>
                                                        </td>
                                                        <td>
                                                            <select className="form-control shadow-none upcoming__match-actions-btn">
                                                                <option>Admin</option>
                                                                <option>Notify</option>
                                                            </select>
                                                        </td>
                                                    </tr>
                                                )
                                            }

                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }
                {
                    upcomingMatchesData?.length === 0 &&
                    <div className="w-100 h-100 card border-0 d-flex justify-content-center align-items-center match__summary-card">
                        <h2>No Data found</h2>
                    </div>
                }
            </div>
            <CreateMatchPopup show={modalShow}
                onHide={() => setModalShow(false)} />
            <ViewMatchPopup show={viewModalShow}
                onHide={() => setViewModalShow(false)} />
        </div>

    )

}


export default UpcomingMatchPage;

