import React, { useEffect, useState } from "react";
import styled from "styled-components";
import cheerio from "cheerio";
import fetch from "node-fetch";
import { BACKEND_URL } from "../constants";
import { useUpdateMatchDetailsMutation } from "../generated/graphql";
import hvantage from "../assets/hvantage.png";
import teacup from "../assets/teacup.png";
import moment from "moment";
import { updateScoresByMatchId } from "../services/today-match-service";

type Player = {
  name: String;
  score: String;
};

interface ScoreboardProps {
  switchTeams: boolean;
  matchUrl: string;
  matchId: string;
}

const Container = styled.div`
  font-weight: bold;
  width: 96%;
  height: 45px;
  position: absolute;
  padding: 5px;
  top: 860px;
  left: 30px;
  display: flex;
  flex-direction: row;
  background: none;
  z-index: 10000000;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
`;

const NewContainer = styled.div`
  width: 100%;
  font-family: "Roboto", sans-serif;
  position: absolute;
  background: none;
  bottom: 80px;
`;

const FlexCol = styled.div`
  font-weight: bold;
  display: flex;
  flex-direction: row;
`;

const TeamName = styled.div`
  font-weight: bold;
  color: white;
  font-size: medium;
  padding: 5px 4px 4px 4px;
  background-color: darkblue;
`;

const ScoreDetail = styled.div`
  color: white;
  wdith: auto;
  font-size: small;
  background-color: darkblue;
  padding: 5px;
  padding: 8px 5px;
  border-radius: 5px;
`;

const ScoreDetailOpponent = styled.div`
  color: white;
  wdith: auto;
  font-size: small;
  background-color: #3182ce;
  padding: 8px 5px;
  margin-left: 2px;
  border-radius: 5px;
`;

const BallInfo = styled.div`
  color: white;
  width: 28px;
  font-size: medium;
  background-color: #3182ce;
  padding: 5px;
  margin-left: 2px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
`;

const Separator = styled.div`
  border-left: 2px solid darkblue;
  height: 35px;
  left: 50%;
  margin-left: 5px;
  margin-right: 5px;
`;

const NewSeparator = styled.div`
  width: 3px;
  background-image: linear-gradient(#ffffff, #c6c4c4, #ffffff);
`;

const OpponentDetails = styled.div`
  font-size: medium;
  padding-top: 5px;
  color: #3182ce;
`;

const ScoresWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const VsWrapper = styled.div`
  height: 30px;
  left: 50%;
  margin-left: 5px;
  margin-right: 5px;
`;

const TimeStampWrapper = styled.div`
  padding: 5px 4px 4px 4px;
  position: absolute;
  right: 0;
`;

const Row = styled.div`
  font-weight: 500;
  width: 96%;
  height: 70px;
  position: absolute;
  left: 30px;
  display: flex;
  flex-direction: row;
  background: none;
  z-index: 10000000;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
`;

const PromoRow = styled.div`
  position: relative;
  width: 96%;
  height: 70px;
  left: 30px;
  background: none;
  z-index: 10000000;
`;

const TimerRow = styled.div`
  position: relative;
  width: 96%;
  height: 30px;
  left: 30px;
  background: none;
  z-index: 10000000;
`;

const Timer = styled.div`
  width: auto;
  height: 30px;
  float: right;
  background-color: white;
  color: black;
  font-weight: bolder;
  padding: 2px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
`;

const ImgPromo1 = styled.div`
  width: auto;
  height: 70px;
  float: left;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
`;

const ImgPromo2 = styled.div`
  width: auto;
  height: 70px;
  float: right;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
`;

const ColumnThirty = styled.div`
  float: left;
  width: 29%;
  height: 100%;
`;
const ColumnFifteen = styled.div`
  float: left;
  width: 14%;
  height: 100%;
  text-align: center;
`;
const ColumnTen = styled.div`
  float: left;
  width: 9%;
  height: 100%;
`;

const InnerRowTop = styled.div`
  background-image: linear-gradient(#103371, #0053df, #05063a);
  text-align: center;
  height: 50%;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
`;

const InnerRowBottom = styled.div`
  background-image: linear-gradient(#ffffff, #c6c4c4, #ffffff);
  text-align: center;
  height: 50%;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
`;

const InnerRowTopLast = styled.div`
  background-image: linear-gradient(#0e58d5, #6e97dd, #4782e5);
  text-align: center;
  height: 50%;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
`;

const InnerRowBottomLast = styled.div`
  background-image: linear-gradient(#0e58d5, #6e97dd, #4782e5);
  text-align: center;
  height: 50%;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
`;

const FirstBox = styled.div`
  background-image: linear-gradient(#103371, #0053df, #05063a);
  text-align: center;
  height: 100%;
  display: flex;
`;

const LastBox = styled.div`
  background-image: linear-gradient(#0e58d5, #6e97dd, #4782e5);
  text-align: center;
  height: 100%;
  display: flex;
`;

const SecondBox = styled.div`
  background-image: linear-gradient(#ffffff, #c6c4c4, #ffffff);
  text-align: center;
  height: 100%;
  display: flex;
`;

const TeamText = styled.div`
  margin: auto auto;
  color: #ffffff;
  font-size: 1.4rem;
  font-family: "Roboto", sans-serif;
`;

const ScoreText = styled.div`
  margin: auto auto;
  color: #000000;
  font-size: 1.7rem;
  font-family: "Roboto", sans-serif;
`;

const FirstText = styled.div`
  color: #ffffff;
  font-family: "Roboto", sans-serif;
`;

const BottomText = styled.div`
  color: #000000;
  font-family: "Roboto", sans-serif;
`;

const BatScoreRow = styled.div`
  font-weight: 500;
  display: flex;
  flex-direction: row;
  z-index: 10000000;
`;

const BatScoreColumn = styled.div`
  float: left;
  width: 50%;
  height: 100%;
`;

const BowlScoreRow = styled.div`
  font-weight: 500;
  display: flex;
  flex-direction: row;
  z-index: 10000000;
`;

const BowlScoreColumn = styled.div`
  float: left;
  width: 50%;
  height: 100%;
`;

const BowlColumn = styled.div`
  float: left;
  height: 100%;
  width: 15%;
  padding: 5px;
  // padding-left: 10px;
  // padding-right: 10px;
`;

const BowlText = styled.div`
  background-color: white;
  width: 25px;
  height: 25px;
  background-size: contain;
  background-position: center;
  border-radius: 50%;
  font-family: "Roboto", sans-serif;
`;

interface Scores {
  innings: String;
  team: String;
  ballScore: String;
  score: String;
  wicket: String;
  bowler: String;
  batsman: String;
  time: String;
}

export const Scoreboard: React.FC<ScoreboardProps> = ({
  switchTeams,
  matchUrl,
  matchId,
}) => {
  const [teamName, setTeamName] = useState("");
  const [score, setScore] = useState("");
  const [overs, setOvers] = useState("");
  const [previousOver, setPreviousOver] = useState(0);
  const [opponent, setOpponent] = useState("");
  const [opponentScore, setOpponentScore] = useState("");
  const [isCricclub, setIsCricclub] = useState<Boolean>(false);
  const [playerOne, setPlayerOne] = useState<Player>();
  const [playerTwo, setPlayerTwo] = useState<Player>();
  const [localTime, setLocalTime] = useState<Date>(new Date());
  const [bowler, setBowler] = useState<String>();
  const [matchSummary, setMatchSummary] = useState<String>();
  const [overInfo, setOverInfo] = useState<String[]>([]);
  const [bowlerScore, setBowlerScore] = useState<String>();
  const [cheerioHtml, setCheerioHtml] = useState<cheerio.Root>();
  const [ballSummary, setBallSummary] = useState<Array<Scores>>([]);
  const [updateMatchDetails] = useUpdateMatchDetailsMutation();
  const [innings, setInnings] = useState("1");
  const [prevOverInfo, setPrevOverInfo] = useState<String[]>([]);

  useEffect(() => {
    let updateInterval: any;
    const updateScoreDetails = async () => {
      // if (
      //   matchUrl &&
      //   (matchUrl.includes("sccacricket") || matchUrl.includes("cricclubs"))
      // ) {
        if (
          matchUrl
        ) {
        setIsCricclub(true);
        setPreviousOver(0.0);
        updateInterval = setInterval(function () {
          cricClubsScoreUpdates();
        }, 3000);
      } else if (matchUrl && matchUrl.includes("chauka")) {
        updateInterval = setInterval(function () {
          chaukaScoreUpdates();
        }, 3000);
      }
    };
    updateScoreDetails();
    return () => {
      clearInterval(updateInterval);
    };
  }, [switchTeams, matchUrl]);

  const cricClubsScoreUpdates = async () => {
    const data = await fetch(matchUrl);
    //   , {
    //   headers: {
    //     'RequestMode' : 'no-cors',
    //     'Accept' : '*/*'
    //   }
    // });
    console.log("data"+ JSON.stringify(data))
    const cheerioAPI: cheerio.Root = cheerio.load(await data.text());
    setCheerioHtml(cheerioAPI);
    setPlayerOne(null);
    setPlayerTwo(null);
    updateTeamScore(cheerioAPI);
    updatePlayerDetails(cheerioAPI);
    updateBowlingInfo();
    updateMatchSummary(cheerioAPI);
    updateScores();
  };

  const updateScores = () => {
    if (prevOverInfo.length !== overInfo.length) {
      const scores = {
        innings: innings,
        team: teamName,
        ballScore: overInfo[overInfo.length - 1],
        score: score.split("/")[0],
        wicket: score.split("/")[1],
        bowler: bowler,
        batsman: playerOne?.name?.includes("*")
          ? playerOne?.name
          : playerTwo?.name,
        time: moment(new Date()).utc().format("HH:mm:ss"),
      };
      const summary = ballSummary;
      summary.push(scores);
      setBallSummary(summary);
      setPrevOverInfo(overInfo);
    }
  };

  const updateTeamScore = ($: cheerio.Root) => {
    $(".col-lg-12").each((_, ele) => {
      let teamNameEle1 = $(ele).find(".teamName")[0];
      let scoresEle1 = $(ele).find("span")[1];
      let oversEle1 = $(ele).find("p")[0];
      let teamNameEle2 = $(ele).find(".teamName")[1];
      let scoresEle2 = $(ele).find("span")[3];
      let oversEle2 = $(ele).find("p")[1];
      console.log("teamname"+ teamNameEle1);
      if (!switchTeams) {
        setTeamName($(teamNameEle1).text().trim());
        setScore($(scoresEle1).text().trim());
        setOvers($(oversEle1).text().trim());
        setOpponent($(teamNameEle2).text().trim());
        setOpponentScore($(scoresEle2).text().trim());
        showFullScoreBoard1(
          $(oversEle1).text().trim(),
          $(scoresEle1).text().trim(),
          $(oversEle2).text().trim().split("/")[1].split("")[0]
        );
      } else {
        setTeamName($(teamNameEle2).text().trim());
        setScore($(scoresEle2).text().trim());
        setOvers($(oversEle2).text().trim());
        setOpponent($(teamNameEle1).text().trim());
        setOpponentScore($(scoresEle1).text().trim());
        showFullScoreBoard2($(oversEle2).text().trim());
      }
    });
  };

  const updateMatchSummary = ($: cheerio.Root) => {
    setMatchSummary($(".match-summary").next().text());
  };

  const updateBowlerSummary = async (bowler: String) => {
    const data = await fetch(matchUrl);
    //   , {
    //   headers: {
    //     'Access-Control-Allow-Origin':'*',
    //     'RequestMode' : 'no-cors'
    //   }
    // });
    const $: cheerio.Root = cheerio.load(await data.text());
    const matchTableEle = $(".fall-of-content")[switchTeams ? 0 : 1];
    $(matchTableEle)
      .find(".fall-text")
      .children("h5")
      .each((_, ele) => {
        const bowlerName = $(ele).text().trim();
        if (bowler) {
          if (bowlerName === bowler?.trim()) {
            setBowlerScore($(ele).next().text().split(",")[0]);
            return;
          }
        }
      });
  };

  const showFullScoreBoard1 = async (
    overs: string,
    scores: string,
    opponentOver: string
  ) => {
    const currentOver = Math.floor(parseFloat(overs.split("/")[0]));
    const prevOver = parseFloat(localStorage.getItem("prevOver1"));
    if (currentOver) {
      if (prevOver !== currentOver) {
        localStorage.setItem("prevOver1", currentOver.toString());
        await updateMatchDetails({
          variables: {
            options: {
              id: matchId.toString(),
              showFullScoreboard: true,
              showScoresOverlay: false,
            },
          },
        });
        // if (ballSummary.length >= 6) {
        updateScoresByMatchId(ballSummary, matchId.toString());
        setBallSummary([]);
        // }
      }
    }
    const totalOvers = Math.floor(
      parseFloat(overs.split("/")[1].split(" ")[0])
    );
    const wickets = parseInt(scores.split("/")[1]);
    if (currentOver) {
      if (
        totalOvers === currentOver ||
        wickets === 10 ||
        parseFloat(opponentOver) > 0.0
      ) {
        setInnings("2");
        await updateMatchDetails({
          variables: {
            options: {
              id: matchId.toString(),
              switchTeams: true,
            },
          },
        });
      }
    }
  };

  const showFullScoreBoard2 = async (overs: string) => {
    const currentOver = Math.floor(parseFloat(overs.split("/")[0]));
    const prevOver = parseFloat(localStorage.getItem("prevOver2"));
    if (currentOver) {
      if (prevOver !== currentOver) {
        localStorage.setItem("prevOver2", currentOver.toString());
        await updateMatchDetails({
          variables: {
            options: {
              id: matchId.toString(),
              showFullScoreboard: true,
              showScoresOverlay: false,
            },
          },
        });

        updateScoresByMatchId(ballSummary, matchId.toString());
        setBallSummary([]);
      }
    }
  };

  const updatePlayerDetails = ($: cheerio.Root) => {
    const matchTableEle = $(".match-table-innings")[!switchTeams ? 0 : 2];
    const tableEle = $(matchTableEle).find("table");
    $(tableEle)
      .children()
      .each((_, tableHeaderBody) => {
        const trEle = $(tableHeaderBody).children();
        let isPlayerOneUpdated = false;
        trEle.each((_, tr) => {
          const playerStatusEle = $(tr).children()[1];
          const isPlayerNotOut = $(playerStatusEle)
            .text()
            .toLowerCase()
            .trim()
            .includes("not out");
          if (isPlayerNotOut) {
            const nameEle = $(tr).children()[0];
            const scoreEle = $(tr).children()[2];
            const name = $($(nameEle).find("a")[0]).text().trim();
            const score = $(scoreEle).text().trim();
            const player = {
              name,
              score,
            };
            if (!isPlayerOneUpdated) {
              isPlayerOneUpdated = true;
              setPlayerOne(player);
            } else if (isPlayerOneUpdated) {
              setPlayerTwo(player);
            }
          }
        });
      });
  };

  const updateBowlingInfo = async () => {
    const data = await fetch(
      matchUrl.replace("fullScorecard", "overbyoverscoreview"));
    //   , {
    //     headers: {
    //       'Access-Control-Allow-Origin':'*',
    //       'RequestMode' : 'no-cors'
    //     }
    //   }
    // );
    const $ = cheerio.load(await data.text());
    const matchTableEle = $(".match-table-innings")[!switchTeams ? 0 : 1];
    const tableEle = $(matchTableEle).find("table");
    $(tableEle)
      .children()
      .each((_, tableHeaderBody) => {
        const trEle =
          $(tableHeaderBody).children()[
            $(tableHeaderBody).children().length - 1
          ];
        const overEle = $(trEle).children()[1];
        const bowler = $(overEle).text().split(" ")[0];
        setBowler(bowler);
        // updateBowlerSummary(bowler);
        let overInfo = [];
        const ballDetailsEle = $(overEle).children()[1];
        $(ballDetailsEle)
          .children()
          .each((_, ballEle) => {
            const score = $(ballEle).text().trim();
            overInfo.push(score);
          });
        setOverInfo(overInfo);
      });
  };

  const chaukaScoreUpdates = async () => {
    const response = await fetch(
      BACKEND_URL + "chauka/" + encodeURIComponent(matchUrl)
    );

    const data = await response.json();
    const $ = cheerio.load(await data.content);
    const tables = $(".table_align_right")[switchTeams ? 2 : 0];
    const tbody = $(tables).children()[1];
    $(tbody)
      .children()
      .each((_, tr) => {
        if ($(tr).text().trim().includes("overs")) {
          const overs = $(tr).find("span")[1];
          setOvers($(overs).text());
        }
      });
    let i = 0;

    setTeamName($(".team_A").text().trim());
    setScore($("#team_A_score").text());
    setOpponent($(".team_B").text().trim());
    setOpponentScore($("#team_B_score").text().trim());
    setIsCricclub(false);
    $(tbody)
      .children()
      .each((_, tr) => {
        if ($(tr).text().toLowerCase().trim().includes("not out")) {
          if (i === 0) {
            const name = $(tr).children()[0];
            const score = $(tr).children()[2];
            const player = {
              name: $(name).text().trim(),
              score: $(score).text().trim(),
            };
            setPlayerOne(player);
            i++;
          } else if (i) {
            const name = $(tr).children()[0];
            const score = $(tr).children()[2];
            if (playerOne?.name !== $(name).text().trim()) {
              const player = {
                name: $(name).text().trim(),
                score: $(score).text().trim(),
              };
              setPlayerTwo(player);
            }
          }
        }
      });
    setLocalTime(new Date());
  };

  useEffect(() => {
    let updateInterval: any;
    updateInterval = setInterval(function () {
      setLocalTime(new Date());
    }, 1000);
    return () => {
      clearInterval(updateInterval);
    };
  }, []);

  return (
    <>
      {isCricclub ? (
        <CricclubScoreBoard
          teamName={teamName}
          score={score}
          opponent={opponent}
          opponentScore={opponentScore}
          overs={overs}
          playerOne={playerOne}
          playerTwo={playerTwo}
          localTime={localTime}
          bowler={bowler}
          overInfo={overInfo}
          matchSummary={matchSummary}
          bowlerScore={bowlerScore}
        />
      ) : null}

      {!isCricclub ? (
        <ChaukaScoreBoard
          teamName={teamName}
          score={score}
          opponent={opponent}
          opponentScore={opponentScore}
          overs={overs}
          playerOne={playerOne}
          playerTwo={playerTwo}
          localTime={localTime}
        />
      ) : null}
    </>
  );
};

const ChaukaScoreBoard: React.FC<{
  teamName: String;
  score: String;
  opponent: String;
  opponentScore: String;
  overs: String;
  playerOne: Player | undefined;
  playerTwo: Player | undefined;
  localTime: Date;
}> = ({
  teamName,
  score,
  opponent,
  opponentScore,
  overs,
  playerOne,
  playerTwo,
  localTime,
}) => {
  return (
    <Container>
      <ScoresWrapper>
        <FlexCol>
          <TeamName>
            {teamName} {score.trim() !== "" ? "- " + score : ""}
          </TeamName>
          <VsWrapper>vs</VsWrapper>
          <TeamName>
            {opponent} {opponentScore.trim() !== "" ? "- " + opponentScore : ""}
          </TeamName>

          <Separator />
          <ScoreDetail>Overs: {overs}</ScoreDetail>
          <Separator />

          <FlexCol>
            {playerOne ? (
              <>
                <ScoreDetail>
                  {playerOne.name + " : " + playerOne.score + ""}
                </ScoreDetail>
              </>
            ) : null}

            {playerTwo ? (
              <>
                <Separator />
                <ScoreDetail>
                  {playerTwo.name + " : " + playerTwo.score + ""}
                </ScoreDetail>
              </>
            ) : null}
          </FlexCol>
          <TimeStampWrapper>
            {localTime.getHours()}:{localTime.getMinutes()}:
            {localTime.getSeconds()}
          </TimeStampWrapper>
        </FlexCol>
      </ScoresWrapper>
    </Container>
  );
};

const CricclubScoreBoard: React.FC<{
  teamName: String;
  score: String;
  opponent: String;
  opponentScore: String;
  overs: String;
  playerOne: Player | undefined;
  playerTwo: Player | undefined;
  localTime: Date;
  bowler: String;
  overInfo: String[];
  matchSummary: String;
  bowlerScore: String;
}> = ({
  teamName,
  score,
  opponent,
  opponentScore,
  overs,
  playerOne,
  playerTwo,
  localTime,
  bowler,
  overInfo,
  matchSummary,
  bowlerScore,
}) => {
  return (
    <NewContainer>
      <TimerRow>
        <Timer>{moment(localTime).format("HH:mm:ss")}</Timer>
      </TimerRow>
      <PromoRow>
        <ImgPromo1>
          <img src={hvantage} />
        </ImgPromo1>
        <ImgPromo2>
          <img src={teacup} />
        </ImgPromo2>
      </PromoRow>

      <Row>
        <ColumnFifteen>
          <FirstBox>
            <TeamText>{teamName}</TeamText>
          </FirstBox>
        </ColumnFifteen>
        <NewSeparator />
        <ColumnTen>
          <SecondBox>
            <ScoreText>{score.trim() !== "" ? " " + score : ""}</ScoreText>
          </SecondBox>
        </ColumnTen>
        <NewSeparator />
        <ColumnThirty>
          <InnerRowTop>
            <BatScoreRow>
              <BatScoreColumn>
                {playerOne ? (
                  <>
                    <FirstText>{playerOne.name}</FirstText>
                  </>
                ) : null}
              </BatScoreColumn>
              <BatScoreColumn>
                {playerOne ? (
                  <>
                    <FirstText>{playerOne.score}</FirstText>
                  </>
                ) : null}
              </BatScoreColumn>
            </BatScoreRow>
          </InnerRowTop>
          <InnerRowBottom>
            <BottomText>Overs: {overs}</BottomText>
          </InnerRowBottom>
        </ColumnThirty>
        <NewSeparator />
        <ColumnThirty>
          <InnerRowTop>
            <BatScoreRow>
              <BatScoreColumn>
                {playerTwo ? (
                  <>
                    <FirstText>{playerTwo.name}</FirstText>
                  </>
                ) : null}
              </BatScoreColumn>
              <BatScoreColumn>
                {playerTwo ? (
                  <>
                    <FirstText>{playerTwo.score}</FirstText>
                  </>
                ) : null}
              </BatScoreColumn>
            </BatScoreRow>
          </InnerRowTop>
          <InnerRowBottom>
            <BottomText>
              {matchSummary && matchSummary.split("(")[0]}
            </BottomText>
          </InnerRowBottom>
        </ColumnThirty>
        <NewSeparator />
        <ColumnThirty>
          <InnerRowTopLast>
            <BowlScoreRow>
              <BowlScoreColumn>
                {bowler ? (
                  <>
                    <FirstText>Bowler: {bowler}</FirstText>
                  </>
                ) : null}
              </BowlScoreColumn>
              <BowlScoreColumn>
                <FirstText>{bowlerScore}</FirstText>
              </BowlScoreColumn>
            </BowlScoreRow>
          </InnerRowTopLast>
          <InnerRowBottomLast>
            <BowlScoreRow>
              {overInfo ? (
                <>
                  {overInfo.map((ball, index) => (
                    <BowlColumn key={index}>
                      <BowlText>{ball}</BowlText>
                    </BowlColumn>
                  ))}
                </>
              ) : null}
            </BowlScoreRow>
          </InnerRowBottomLast>
        </ColumnThirty>
        <NewSeparator />
        <ColumnFifteen>
          <LastBox>
            <TeamText>{opponent}</TeamText>
          </LastBox>
        </ColumnFifteen>
      </Row>
    </NewContainer>
  );
};
