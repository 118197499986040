import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal'
import './ViewMatch.css';

const ViewMatchPopup = (props) => {

    return (
        <div>
            <Modal
                {...props}
                size="md"
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Update Match
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>

                        <div className='mt-3 d-flex'>
                            <label className='form-label w-25'>Match Date</label>
                            <input type='date' className='w-75 form-control shadow-none' />
                        </div>
                        <div className='mt-3 d-flex'>
                            <label className='form-label w-25'>Match Name</label>
                            <input type='text' className='w-75 form-control shadow-none' />
                        </div>
                        <div className='mt-3 d-flex'>
                            <label className='form-label w-25'>Location</label>
                            <input type='text' className='w-75 form-control shadow-none' />
                        </div>
                        <div className='mt-3 d-flex'>
                            <label className='form-label w-25'>ccMatchID</label>
                            <input type='text' className='w-75 form-control shadow-none' />
                        </div>
                        <div className='mt-3 d-flex'>
                            <label className='form-label w-25'>ccSeriesID</label>
                            <input type='text' className='w-75 form-control shadow-none' />
                        </div>
                        <div className='mt-3 d-flex'>
                            <label className='form-label w-25'>FixtureID</label>
                            <input type='text' className='w-75 form-control shadow-none' />
                        </div>
                        <div className='mt-3 d-flex'>
                            <label className='form-label w-25'>Match Time</label>
                            <input type='text' className='w-75 form-control shadow-none' />
                        </div>
                        <div className='mt-3 d-flex'>
                            <label className='form-label w-25'>Scraping Link</label>
                            <input type='text' className='w-75 form-control shadow-none' />
                        </div>
                        <div className='mt-3 d-flex'>
                            <label className='form-label w-25'>Series Name</label>
                            <input type='text' className='w-75 form-control shadow-none' />
                        </div>
                        <div className='mt-3 d-flex'>
                            <label className='form-label w-25'>Youtube URL</label>
                            <input type='text' className='w-75 form-control shadow-none' />
                        </div>
                        <div className='mt-3'>
                            <button className='w-100 btn btn-success'>Update</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )

}

export default ViewMatchPopup;